import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.less']
})
export class AccountMenuComponent implements OnInit {

  constructor() { }

  // links = ['Account Overview', 'Settings', 'Laboratories', 'Devices', 'Users', 'Patient-form' ];
  // activeLink = this.links[0];

  accountLinks = [
    {
      name: "General",
      url: "/settings",

    },
    {
      name: "Laboratories",
      url: "/laboratories",
    },
    {
      name: "Devices",
      url: "/devices",
    },
    {
      name: "Users & Permissions",
      url: "/users",
    },
    {
      name: "patient_form",
      url: "/patient_form",
    },
  ]

  activeLink = this.accountLinks[0];

  ngOnInit(): void {
  }

}
