import { ChangelogModel } from './models/changelog.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangelogComponent } from './modules/accounts/components/changelog/changelog.component';
import { ApiService } from 'src/app/services/api.service';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'vidiia-cloud';
  study = '';
  versionNumber = '0.9.0';
  public user;
  isLogged: boolean = false;
  account = 'Loading...';
  subAccount = '';
  accountLinks = [
    /*{
      name: "Notifications",
      url: "notifications",
      icon: "notifications"
    },
    {
      name: "Account Overview",
      url: "account_overview",
      icon: "home"
    },*/
    {
      name: "Settings",
      url: "settings",
      icon: "settings"
    }
  ]

  testLinks = [
    {
      name: "Patients",
      url: "patients",
      icon: "person"
    },
    {
      name: "Tests",
      url: "samples",
      icon: "view_week"
    },
  ];

  lftLinks = [
    {
      name: "Patients",
      url: "lft/patients",
      icon: "person"
    },
    {
      name: "Tests",
      url: "lft/samples",
      icon: "view_week"
    },
  ];

  reportsLinks = [
    {
      name: "Public Health of England",
      url: "reports",
      icon: "table_view"
    },
  ]

 analyticsLinks = [
    {
      name: "Dashboard",
      url: "dashboard",
      icon: "dashboard"
    }
  ]


  constructor(
    public auth: AuthService,
    private  router: Router,
    private api: ApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangelogComponent>
  ) {}

  async getAccount(){
    this.account = this.auth.account.split('-').join(' ');
    this.subAccount = this.auth.subAccount;
    const acc = await this.api.getAccount() as any;
    this.study = acc.study;
    this.reportsLinks[0].url = this.study == 'LFT' ? "lft/reports" : "reports";
  }

  ngOnInit(){
    this.api.getChangelog().then((logs: ChangelogModel[]) => {
      this.versionNumber = logs[0].version;
    }).catch(_ => this.versionNumber = '' as any);
    this.auth.isLogged().then(async(logged) => {
        if( logged ) {
          this.isLogged = true
          await this.getAccount();

        }
        else{
          this.isLogged = false;
          this.account = '';
          this.subAccount = '';
        }


    });
  }
  ngAfterViewInit(){
    this.auth.userLoggedIn.subscribe(async() => {
      this.isLogged = true;
      this.auth.getUser().then(async(user) => {
        await this.getAccount();
      });

    });
    this.auth.userLoggedOut.subscribe(_ => this.isLogged = false);
  }

  async launchChangeLog(){
    const dialogRef = this.dialog.open(ChangelogComponent, {
      width: '90%'
    });

    dialogRef.afterClosed().subscribe((accepted) => {
      if(accepted){

      }
    });
  }

}
