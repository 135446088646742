import { ConfirmComponent } from './../../../../common/confirm/confirm.component';
import { AccountModel } from './../../../../models/account.model';
import { SetResultComponent } from './../../../../common/setResult/set-result.component';
import { LFTObject } from './../../../../models/lft_object.model';
import { AmplifyService } from 'aws-amplify-angular';
import { SampleDetailComponent } from '../sample-detail/sample-detail.component';
import { TestSampleModel } from '../../../../models/test.model';
import { TestModel } from '../../../../models/test.model';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { SampleModel } from 'src/app/models/sample.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import * as HighCharts from 'highcharts';
import * as moment from 'moment';

@Component({
  selector: 'app-lfttest-detail',
  templateUrl: './lfttest-detail.component.html',
  styleUrls: ['./lfttest-detail.component.less']
})
export class LFTTestDetailComponent implements OnInit {
  test: TestModel;
  tests: TestModel[];
  index: number;
  imageInputURL = '';
  lftObject: LFTObject;
  new_sample_id = '';
  accountInfo: AccountModel;
  loading = false;
  @ViewChild("chartContainer", {static: true}) chartContainer: ElementRef;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private amplify: AmplifyService,
    @Inject(MAT_DIALOG_DATA) public data: {test: TestModel, tests: TestModel[], index: number},
    public dialogRef: MatDialogRef<LFTTestDetailComponent>) {

      this.test = data.test;
      this.tests = data.tests;
      this.index = data.index;

    }

  async ngOnInit(){
    // this.form = this.formBuilder
    this.accountInfo = await this.api.getAccount() as any;
   console.log(this.test);
   this.dialogRef.disableClose = true;
   this.dialogRef.backdropClick().subscribe(_ => {
     let cn = confirm('Sure ?')
     if (cn) {
       this.dialogRef.close();
     }
   })
   this.loadTest();
  }

  private async loadTest(){
    this.imageInputURL = '';
    this.lftObject = null;
    if(this.test['tube'] == 'unavailable'){
      this.lftObject = new LFTObject();
      this.lftObject.Image = this.test.image;
      this.imageInputURL = `https://vidiia-lft-images.s3.eu-west-2.amazonaws.com/${this.lftObject.Image}`;
      this.new_sample_id = this.test.bundle;
      this.loading = true;


      const reader = new FileReader();
      const res = await fetch(this.imageInputURL);
      const downloadedImage = await res.blob();
      reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = rs => {
            //console.log(e.target.result);
            //data:image/png;base64,
            //data:application/octet-stream;base64
            //.replace(/^data:image\/\w+;base64,/, '');

            const imgBase64Path = (e.target.result as string).replace(/^data:\w+\/\w+(-\w+)*;base64,/, '');
            //console.log(imgBase64Path);
            //console.log(this.test['result']);
            const result = this.test['result'] == 'Positive' ? 0 : this.test['result'] == 'Negative' ? 1 : 2;
            this.api.readLFTTest(imgBase64Path, result, this.test['number']).toPromise().then(resp => {
              //this.imageData = '';
              //this.uploading = false;
              //console.log(resp);
              const output = resp as any;
              if(output['utr']){
                this.test['_id'] = output['id'];
                this.test['bundle'] = output['utr'];
                this.test['tube'] = output['utr'];
                this.test['test_result_number'] = output['confidence'];
                this.test['ai_result'] = output['status'];
                this.loading = false;
                this.api.insertSimpleTest({id: this.test.id, _id: output['id'], bundle: output['utr']} as any).subscribe(resp => {
                  this.api.insertSample({id: this.test['samples_id'], tube: output['utr']} as any).subscribe(resp => {
                    this.api.insertTestSample({id: this.test['tests_samples_id'], ai_result: output['status'], test_result_number: output['confidence']} as any).subscribe(resp => {

                    }, err => this.loading = false);
                  }, err => this.loading = false);
                }, err => this.loading = false);
                //this.ltf = output;
                //this.f.sample_id.setValue(output['utr']);
              }else{
                //this.f.sample_id.setValue('unavailable');
                //this.imageData = imgBase64Path;
                this.loading = false;
              }

            }).catch(_ => {
              //this.uploading = false;
              //this.f.sample_id.setValue('unavailable');
              //this.imageData = imgBase64Path;
              this.loading = false;
            }).finally(() => {
              //this.imageName = fileInput.target.files[0].name;
              //this.isLoading = false;

            });

              //this.isLoading = false;
          };
        };
        reader.readAsDataURL(downloadedImage);


    }else{
      this.api.getLFTTest(this.test.bundle).then(resp => {
        console.log(resp);
        this.lftObject = resp['Results'][0] as any;
      }).catch(err => {
        alert("LFT Api is not working");

      }).finally(() => {
        this.imageInputURL = `https://lft-medlab-s3.s3.eu-west-2.amazonaws.com/${this.lftObject.Image}`;
      });
    }

  }

  async setPositiveNegative(value: number){
    if(this.new_sample_id == ''){
      alert("Please enter the 6 digit sample id");
    }else{
      this.loading = true;
      const t = this.tests[this.index];
      t['reviewed'] = "Yes";
      t['position'] = 1;
      t['user_result'] = value == 1 ? 'Positive' : value == 0 ? 'Inconclusive' : 'Negative';
      this.updateTest(t);
      let test: TestModel = await this.api.getTests({'tests.id': t['tests_id']}) as any;
      test = test[0] as any;
      test.bundle = this.new_sample_id;
      t['bundle'] = this.new_sample_id;
      test.samples = [];
      this.api.insertTest(test).subscribe(_ => {
        this.loading = false;
        const dialogRef = this.dialog.open(ConfirmComponent, {
          width: '60%',
          data: {question: 'Issue certificate?'}
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if(confirmed){
            this.loading = true;
            this.api.sendPositiveEmail(t, this.accountInfo.study, this.auth.subAccount || this.auth.account)
              .then(_ => {
                alert("Notification successfully sent");
                t['notified'] = 'Yes';
                this.api.insertTestSample({id: t['tests_samples_id'], notified: true} as any).subscribe(resp => {});
                this.goNext();
              })
              .catch(err => {
                alert("Couldn't notify the patient");
                console.log("Couldn't notify the patient", err);
              })
              .finally(() => {
                this.loading = false;
              })
          }else this.goNext();
        });
      });
    }
  }

  goBack(){

    this.index--;
    this.test = this.tests[this.index];
    this.loadTest();
  }

  goNext(){
    if(this.index == this.tests.length - 1) this.dialogRef.close(this.tests);
    this.index++;
    this.test = this.tests[this.index];
    this.loadTest();
  }

  isCorrect(){
    const t = this.tests[this.index];
    t['reviewed'] = "Yes";
    t['position'] = 1;
    t['user_result'] = t['ai_result'];
    this.updateTest(t);
  }

  selectSample(test: TestSampleModel, sample: SampleModel){

  }

  showDialog(){
    const dialogRef = this.dialog.open(SetResultComponent, {
      width: '90%',
      data: {}
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      console.log(result);
      const t = this.tests[this.index];
      t['reviewed'] = 'Yes';
      t['user_result'] = result;
      t['position'] = 1;
      this.updateTest(t);
    });
  }

  updateTest(t: TestModel){
    this.loading = true;
    this.api.insertTestSample({id: t['tests_samples_id'], reviewed: true, position: 1, user_result: t['user_result']} as any).subscribe(resp => {
      const lft_result = t['user_result'] == 'Positive' ? 0 : t['user_result'] == 'Negative' ? 1 : 2;
      if(this.test['tube'] != 'unavailable'){
        this.api.setLFTReviewed(t.bundle, lft_result).toPromise().then().catch(_ => console.log('LFT Api unavailable')).finally(() => {
          this.loading = false;

          const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '60%',
            data: {question: 'Issue certificate?'}
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if(confirmed){
              this.loading = true;
              this.api.sendPositiveEmail(t, this.accountInfo.study, this.auth.subAccount || this.auth.account)
                .then(_ => {
                  alert("Notification successfully sent");
                  t['notified'] = 'Yes';
                  this.api.insertTestSample({id: t['tests_samples_id'], notified: true} as any).subscribe(resp => {});
                  this.goNext();
                })
                .catch(_ => alert("Couldn't notify the patient"))
                .finally(() => {
                  this.loading = false;
                })
            }else this.goNext();
          });

        });
      }else this.loading = false;
    }, err => {
      alert("Object couldn't be saved, please try again later.")
      this.loading = false;
    });
  }

  close(){
    this.dialogRef.close(this.tests);
  }

}
