<app-account-menu></app-account-menu>

<div class="result-box">

  <mat-tab-group animationDuration="0ms">
    <mat-tab label="ROLES">

    </mat-tab>

    <mat-tab label="USERS">
      <div class="">
        <table mat-table [dataSource]="users">

          <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef> Firstname </th>
            <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
          </ng-container>

          <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef> Surname </th>
            <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> E-mail </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplayUsers"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayUsers"></tr>

        </table>

      </div>
    </mat-tab>

    <mat-tab label="GROUPS">
      <div class="">
        <table mat-table [dataSource]="groups">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef> Surname </th>
            <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> E-mail </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="columnsToDisplayGroups"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayGroups"></tr>

        </table>

      </div>
    </mat-tab>

  </mat-tab-group>
  <!-- -->


</div>
