import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-forgotten',
    templateUrl: './forgotten.component.html',
    styleUrls: ['./forgotten.component.css'],
    host: {'class': 'component-wrapper'}
})
export class ForgottenComponent implements  OnInit{
    loginForm: FormGroup;
    submitted = false;
    username = '';
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService){
        this.username = localStorage.getItem('user');
    }
    async ngOnInit() {

        this.loginForm = this.formBuilder.group({
            code: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(8)]]
        });
        this.username = this.route.snapshot.queryParams['email'];


    }
    get f() { return this.loginForm.controls; }
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        this.auth.changePasswordByCode(this.username, this.f.code.value, this.f.password.value)
            .then((data) => {
                alert('Se ha modificado exitosamente la contraseña');
                this.router.navigate(['/login']);
            })
            .catch((data) => alert(data.message));
    }
}