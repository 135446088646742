import { GroupModel } from 'src/app/models/group.model';
import { LaboratoryModel } from 'src/app/models/laboratory.model';
import { ConfirmComponent } from './../../../common/confirm/confirm.component';
import { MatPaginator } from '@angular/material/paginator';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

import { ApiResponseModel } from 'src/app/models/api-response.model';
import { PatientModel } from 'src/app/models/patient.model';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.less']
})
export class PatientsComponent implements OnInit{
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  patients: PatientModel[] = [];
  laboratories: LaboratoryModel[] = [];
  patientGroups: GroupModel[] = [];
  // dataSource: Patient[];
  datasource = null;
  columnsToDisplay: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    public dialog: MatDialog,
    private auth: AuthService) { }

    async ngOnInit() {


      // let patient = new PatientModel();
      // patient.firstname = 'Geraldine';
      // patient.surname = 'Macias';
      // patient.email = 'geral@gmail.com';
      // patient.mobile_number = '55556677';

      // this.api.insertPatient(patient).subscribe((respUser: ApiResponseModel) => {
      //   console.log('respUser', respUser);

     // })
     //this.createPatient();

      await this.loadData();
    }

    async loadData(refresh?: boolean){
      console.log("Get patients");
      this.patients = await this.api.getPatients({status: 1});
      console.log(this.patients);
      this.patientGroups = await this.api.getPatientGroups() as any;
      this.laboratories = await this.api.getLaboratories({status: 1});
      this.columnsToDisplay = ['firstname', 'surname', 'email', 'group', 'mobile_number', 'created', 'edit', 'delete'];
      if(refresh){
        this.datasource.data = this.patients;
      }else{
        this.datasource = new MatTableDataSource<PatientModel>(this.patients);
        this.datasource.sort = this.sort;
        this.datasource.paginator = this.paginator;
      }
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.datasource.filter = filterValue.trim().toLowerCase();
    }

    /*createPatient() {
    let patient = new PatientModel();

      patient.firstname = "Marie"
      patient.surname = "G";
      patient.address1 = "General Rebollar 1"
      patient.address2 = "San Miguel Chapultepec"
      patient.country = ""
      patient.postcode = "11850"
      patient.town = "CDMX"
      patient.email = "marie@gmail.com"
      patient.mobile_number = "55556789"

      this.api.insertPatient(patient).subscribe((respPatient: ApiResponseModel) => {
        //console.log("Patient")
        //console.log('resp Patient', respPatient);
      });

    }*/
    // Open dialog for create laboratory
    openEditPatient(p?: PatientModel) {

      //console.log('editPatient');
      //console.log(p);

      const dialogRef = this.dialog.open(AddPatientComponent, {
        width: '600px',
        data: {patient: p, laboratories: this.laboratories, groups: this.patientGroups}
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if(result){
          await this.loadData(true);
        }
        //console.log(`Dialog result: ${result}`);
      });
    }

    delete(el: PatientModel) {

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '300px',
      });

      dialogRef.afterClosed().subscribe((accepted) => {
        if(accepted){
          this.api.insertPatient({'id': el.id, 'status': 0} as any).subscribe(async (_) => {
            console.log('delete patient');
            this.loadData(true);
          });
        }
      });

      //update status to 0

    }


}
