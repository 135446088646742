import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class RootGuardService implements CanActivate{
    constructor(private  auth: AuthService, private  router: Router){
    }
    canActivate(){
        return this.auth.isLogged().then((logged) => {
            if( logged ) this.router.navigate(['/home']);
            else return !logged;
        });
    }
}
