<div>

  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Login</mat-card-title>
      <mat-card-subtitle>Change password</mat-card-subtitle>
    </mat-card-header>

    <form class="form-horizontal ion-margin-top" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-card-content  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Old password" formControlName="old_password" type="password" >
          <mat-error *ngIf="f.old_password.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" formControlName="password" type="password">
          <mat-error *ngIf="f.password.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="f.password.errors">
            Password must be a least <strong>8 characters</strong> long
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="First name" formControlName="name" type="name">
          <mat-error *ngIf="f.name.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Last name" formControlName="family_name" type="family_name">
          <mat-error *ngIf="f.family_name.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <button mat-raised-button color="primary" class="btn-block">Set password</button>
      <br/><a href="/login">Go to login</a>
    </form>
  </mat-card>

</div>
