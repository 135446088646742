import { LaboratoryModel } from './../../../../../models/laboratory.model';
import { GroupModel } from 'src/app/models/group.model';
import { RoleModel } from './../../../../../models/role.model';
import { UserModel } from './../../../../../models/user.model';
import { DeviceModel } from './../../../../../models/device.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.less']
})
export class AddUserComponent implements OnInit {

  form: FormGroup = this.formBuilder.group({
    id: [''],
    email: ['', [Validators.required, Validators.email]],
    firstname: ['', [Validators.required] ],
    surname: ['', [Validators.required] ],
    roles_id: ['', [Validators.required] ],
    laboratories_id: ['', [Validators.required] ],
  });
  roles: RoleModel[] = [];
  groups: GroupModel[] = [];
  selectedGroups: GroupModel[] = [];
  mailDisabled = false;
  laboratories: LaboratoryModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {user: UserModel, roles: RoleModel[], groups: GroupModel[], laboratories: LaboratoryModel[]},
    public dialogRef: MatDialogRef<AddUserComponent>) {
      this.laboratories = data.laboratories;
      this.roles = data.roles;
      this.groups = data.groups;
      console.log(data);

      if(data.user){
        this.selectedGroups = data.user.groups;
        if(data.user.id) this.mailDisabled = true;
        this.elementToForm(data.user);
      }
    }

  async ngOnInit(){
    // this.form = this.formBuilder
  }

  get f() { return this.form.controls; }

  private async elementToForm(el: any){
    for(let k of Object.keys(this.f))
        if(this.f[k]) this.f[k].setValue(el[k]);
    //this.f.usuario_id.setValue(this.auth.usuario.id);
    //this.f.fecha.setValue(moment(evento.fecha).utcOffset(0).format('YYYY-MM-DDTHH:mm:00-05:00'));
    //this.f.tipo.setValue(evento.tipo.toLowerCase());
    //this.id = evento.id;
  }

  addElement(){
    console.log("addElement")
    if( this.form.invalid){
      return;
    }
    console.log(this.form.value)
   //console.log(this.device)


    this.api.insertUser(this.form.value).subscribe((respUser: ApiResponseModel) => {
        console.log("device")
        console.log('respUser', respUser);
        const userId = respUser.insertId || this.f.id.value;
        this.api.insertUserGroup({users_id: userId, groups: this.selectedGroups.map(x => x.id)}).subscribe(_ => this.dialogRef.close(true));

      });
  }

  isChecked(p: GroupModel){
    return this.selectedGroups.filter(x => x.id === p.id).length > 0;
  }

  check(p: GroupModel){
    if(!this.isChecked(p)) this.selectedGroups.push(p);
    else this.selectedGroups = this.selectedGroups.filter(x => x.id !== p.id);
  }

  cancel(){
    this.dialogRef.close()
  }

}
