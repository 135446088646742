<app-account-menu></app-account-menu>
<div class="result-box">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner *ngIf="!dataSource" ></mat-spinner>
  </div>
  <div class="">
    <div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
      <mat-form-field class="full-width" *ngIf="dataSource"  fxFlex="70" fxLayout="column">
        <span matPrefix><mat-icon color="primary">search</mat-icon></span>
        <mat-label>Search by</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. email" #input>
      </mat-form-field>
      <button *ngIf="dataSource"  fxFlex="30" fxLayout="column" mat-raised-button color="primary" (click)="openEdit()">Add laboratory</button>
    </div>
    <div class="mast-elevation-z8">
      <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort
      [hiddenColumns]="[columnsToDisplay.length - 2, columnsToDisplay.length - 1]" >

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef> Region </th>
          <td mat-cell *matCellDef="let element"> {{element.region}} </td>
        </ng-container>

        <ng-container matColumnDef="lat">
          <th mat-header-cell *matHeaderCellDef> Latitude </th>
          <td mat-cell *matCellDef="let element"> {{element.lat}} </td>
        </ng-container>

        <ng-container matColumnDef="lon">
          <th mat-header-cell *matHeaderCellDef> Longitude </th>
          <td mat-cell *matCellDef="let element"> {{element.lon}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef> Created </th>
          <td mat-cell *matCellDef="let element"> {{element.created_at.substring(0, 10)}} </td>
        </ng-container>

        <!--

        <ng-container matColumnDef="address1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Address 1 </th>
          <td mat-cell *matCellDef="let element"> {{element.address1}} </td>
        </ng-container>

        <ng-container matColumnDef="address2">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Address 2 </th>
          <td mat-cell *matCellDef="let element"> {{element.address2}} </td>
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Country  </th>
          <td mat-cell *matCellDef="let element"> {{element.country}} </td>
        </ng-container>

        <ng-container matColumnDef="postcode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Postcode  </th>
          <td mat-cell *matCellDef="let element"> {{element.postcode}} </td>
        </ng-container>

      -->
        <ng-container matColumnDef="town">
          <th mat-header-cell *matHeaderCellDef> Town  </th>
          <td mat-cell *matCellDef="let element"> {{element.town}} </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" (click)="openEdit(element)">
              <mat-icon class="mat-18">mode</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button color="warn" (click)="deleteLab(element)">
                <mat-icon class="mat-18">delete</mat-icon>
            </button>
          </td>
        </ng-container>


        <!-- <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row> -->
        <!-- <mat-row *matRowDef="let row; columns: columnsToDisplay"  (click)="onRowClicked(row)"></mat-row> -->
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" (click)="selectLaboratory(myRowData)"></tr>

      </table>
      <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="40px" *ngIf="dataSource">
      <button mat-stroked-button color="primary" (click)="exporter.exportTable('xlsx')">Export / Download</button>
    </div>

  </div>


</div>
