import { AddPermissionComponent } from './../add-permission/add-permission.component';
import { PermissionModel } from './../../../../../models/permission.model';
import { AddRoleComponent } from './../add-role/add-role.component';
import { RoleModel } from './../../../../../models/role.model';
import { ConfirmComponent } from './../../../../../common/confirm/confirm.component';
import { AddUserComponent } from './../add-user/add-user.component';
import { UserModel } from './../../../../../models/user.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DeviceModel } from 'src/app/models/device.model';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.less']
})
export class RolesComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort2: MatSort;
  @ViewChild(MatPaginator) paginator2: MatPaginator;
  dataSource = null;
  dataSource2 = null;
  columnsToDisplay: string[];
  columnsToDisplay2: string[];
  roles: RoleModel[] = [];
  permissions: PermissionModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog) { }

    async ngOnInit() {
      this.roles = await this.api.getRoles() as any;
      this.permissions = await this.api.getPermission() as any;
      await this.loadData();

    }

    async loadData(refresh?: boolean){
      console.log(this.roles);
      this.columnsToDisplay = ['id', 'name', 'description', 'edit', 'delete'];
      this.columnsToDisplay2 = ['id', 'name', 'edit', 'delete'];
      if(refresh){
        this.roles = await this.api.getRoles({}, true) as any;
        this.permissions = await this.api.getPermission() as any;
        this.dataSource.data = this.roles;
        this.dataSource2.data = this.permissions;
        console.log(this.dataSource.data);
      }else{
        this.dataSource = new MatTableDataSource<RoleModel>(this.roles);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource2 = new MatTableDataSource<PermissionModel>(this.permissions);
        this.dataSource2.sort2 = this.sort2;
        this.dataSource2.paginator2 = this.paginator2;
      }
    }

    select(row) {
      console.log('Row clicked: ', row);
    }

    deleteDevice(id: number) {
      //change this functions for logic delete
      this.api.deleteDevice({id: id}).then(async _ => {
        console.log("delete device:", id)
      })
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openAddDevice(){
      console.log("openAddDevice");
      const dialogRef = this.dialog.open(AddUserComponent, {
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    openEdit(el?: RoleModel) {
      const dialogRef = this.dialog.open(AddRoleComponent, {
        width: '600px',
        data: {role: el, permissions: this.permissions}
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if(result)
          await this.loadData(true);
      });
    }

    openEditPermission(el?: PermissionModel) {
      const dialogRef = this.dialog.open(AddPermissionComponent, {
        width: '600px',
        data: {permission: el}
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if(result)
          await this.loadData(true);
      });
    }

    delete(el: RoleModel) {

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '300px',
      });

      dialogRef.afterClosed().subscribe((accepted) => {
        if(accepted){
          this.api.deleteRole({'id': el.id} as any).then(async (_) => {
            console.log('delete device');
            this.loadData(true);
          });
        }
      });

      //update status to 0

    }


}
