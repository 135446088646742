import { ChangelogModel } from './../models/changelog.model';
import { AccountModel } from './../models/account.model';
import { PredictedFeatures, SampleModel } from 'src/app/models/sample.model';
import { TestSampleModel } from './../models/test.model';
import { UserGroupModel } from 'src/app/models/user-group.model';
import { PermissionModel } from './../models/permission.model';
import { RoleModel } from './../models/role.model';
import { GroupModel } from './../models/group.model';
import { UserModel } from '../models/user.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { LaboratoryModel } from '../models/laboratory.model';
import { DeviceModel } from '../models/device.model';
import { PatientModel } from '../models/patient.model';
import { TestModel } from '../models/test.model';
import * as mimemessage from 'mimemessage';
import * as Crypto from 'crypto-js';

@Injectable()
export class ApiService {
  url = 'https://lvjcsv4c60.execute-api.eu-west-2.amazonaws.com/dev/';
  lft = 'https://qowcd39q40.execute-api.eu-west-2.amazonaws.com/prod/';
  lft_read = 'https://414dibekma.execute-api.eu-west-2.amazonaws.com/prod/';
  lft_read_key = 'DBg7jx8Ibc3AhwbRXqfpl4WtFd9pYXzHahN44206';
  lft_read_account = '4883213c56a34054a4baee38b0d4d1c1';
  headers: HttpHeaders = new HttpHeaders();
  headersJSON: HttpHeaders = new HttpHeaders();
  headersNC: HttpHeaders = new HttpHeaders();
  headersLFT: HttpHeaders = new HttpHeaders();
  token = '';
  user: any;
  catalog = [];
  constructor(private http: HttpClient /*, private storage: Storage*/) {}
  public setHeaders(token: string) {
    this.token = token;
    this.headers = new HttpHeaders()
      .append('Authorization', this.token)
      .append('Content-Type', 'application/x-www-form-urlencoded');
    this.headersNC = new HttpHeaders().append('Authorization', this.token);
    this.headersJSON = new HttpHeaders()
      .append('Authorization', this.token)
      .append('Content-Type', 'application/json');
  }

  public readLFTTest(image: string, result: number, reference: string){
    const headers: HttpHeaders = new HttpHeaders().append('x-api-key', this.lft_read_key);
    const params = {
      result: result,
      reference: reference,
      accountCode: this.lft_read_account,
      image: image
    };
    return this.http.post(this.lft_read , params, {headers});
  }

  public getLFTTest(test_id: string){
    //https://rvke3p2agl.execute-api.us-east-1.amazonaws.com/prod?id=0108800111704420105822631T1-17230602251224-21047095
    const headers: HttpHeaders = new HttpHeaders({'x-api-key': this.lft_read_key});

    return this.http
      .get(this.lft + `?utr=${test_id}&accountCode=${this.lft_read_account}`, { headers: headers })
      .toPromise();
  }

  public setLFTReviewed(utr: string, result: number) {
    const headers: HttpHeaders = new HttpHeaders({'x-api-key': this.lft_read_key});
    const obj = {
      utr: utr,
      result: result.toString(),
      accountCode: this.lft_read_account
    };
    return this.http.post(this.lft , obj, { headers });
  }

  public getChangelog(){
    return new Promise((resolve, reject) => {
        return this.http.get(`/assets/changelog.json`, {}).toPromise().then((logs: ChangelogModel[]) => {
            resolve(logs);
        }).catch(_ => reject(false));
    });
  }

  /** Users */
  public getUsers(params?: any) {
    return new Promise((resolve, reject) => {
      this.get('users', params, 0)
        .then(async(users: UserModel[]) => {
          let groups: GroupModel[] = await this.getGroups() as any;
          let usergroups: UserGroupModel[] = await this.getUserGroups() as any;
          for(let i = 0; i < users.length; i++){
            let ug = usergroups.filter(x => x.users_id === users[i].id).map(x => x.groups_id);
            users[i].groups = groups.filter(x => ug.includes(x.id));
          }
          resolve(users);
        })
        .catch(err => reject(err));
    });
  }

  public insertUser(obj: UserModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'users', obj, { headers });
  }

  public deleteUser(params?: any) {
    return this.del('users', params);
  }

  public insertSample(obj: SampleModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'samples', obj, { headers });
  }

  public insertTestSample(obj: TestSampleModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'tests_samples', obj, { headers });
  }

  public insertTest(obj: TestModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'tests', obj, { headers });
  }

  public insertSimpleTest(obj: TestModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'simple_tests', obj, { headers });
  }

  /* Groups */
  public insertGroup(obj: GroupModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'groups', obj, { headers });
  }

  public getGroups(params?: any, force?: boolean) {
    return new Promise((resolve, reject) => {
      if(!this.catalog['groups'] || force){
        this.get('groups', params, 0).then(groups => {
          this.catalog['groups'] = groups;
          resolve(this.catalog['groups']);
        }).catch(err => reject(err));
      }else resolve(this.catalog['groups']);
    });
  }

  public deleteGroup(params?: any) {
    return this.del('groups', params);
  }

  /* Patient Groups */
  public insertPatientGroup(obj: GroupModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'patient_groups', obj, { headers });
  }

  public getPatientGroups(params?: any, force?: boolean) {
    return new Promise((resolve, reject) => {
      if(!this.catalog['patient_groups'] || force){
        this.get('patient_groups', params, 0).then(patient_groups => {
          this.catalog['patient_groups'] = patient_groups;
          resolve(this.catalog['patient_groups']);
        }).catch(err => reject(err));
      }else resolve(this.catalog['patient_groups']);
    });
  }

  public deletePatientGroup(params?: any) {
    return this.del('patient_groups', params);
  }

  /** Users_groups */
  public insertUserGroup(obj: any) {
    const headers = this.headers;
    return this.http.post(this.url + 'users_groups', obj, { headers });
  }
  public getUserGroups(params?: any) {
    return this.get('users_groups', params, 0);
  }

  public deleteUserGroup(params?: any) {
    return this.del('users_groups', params);
  }

  /** Test */
  public getTests(params?: any): Promise<TestModel[]>{
    return this.get('tests', params, 0) as any;
  }

  public async fullTests(params?: any): Promise<TestModel[]>{
    const tests = await this.get('full_tests', params, 0) as any;
    let patient_ids = [...new Set(tests.filter(x => x['patient_id'] != null).map(x => x['patient_id']))];
    //const patient: PatientModel[] = await this.get('patient', {id: {value: patient_ids}}) as any;
    //const patient_documents: any[] = await this.get('patients_type_id', {patient_id: {value: patient_ids}}) as any;
    /*const features: PredictedFeatures[] = await this.get('predicted_features', {samples_id: {value: sample_ids}}, 0) as any;

    for(let s of tests){
      //s.predicted_features = features.filter(x => x['samples_id'] === s.id);
      s.patient = patient.find(x => x.id === s.patient_id as any);
      s.email = s.patient.email;
      if(s.patient) s.patient_id = patient_documents.find(x => x['patient_id'] == s.patient.id);
    }*/
    return tests;
  }
  public getSamples(params?: any): Promise<SampleModel[]>{
    return new Promise(async(resolve, reject) => {
      const samples: SampleModel[] = await this.get('samples', params, 0) as any;
      console.log(samples);
      let sample_ids = [...new Set(samples.map(x => x['id']))];
      const features: PredictedFeatures[] = await this.get('predicted_features', {samples_id: {value: sample_ids}}, 0) as any;
      for(let s of samples){
        s.predicted_features = features.filter(x => x['samples_id'] === s.id);
      }
      console.log(samples);
      resolve(samples);
    });
  }

  public getTestsSamples(params?: any): Promise<TestSampleModel[]>{
    return new Promise(async (resolve, reject) => {
      let testsamples: TestSampleModel[] = await this.get('tests_samples', params, 0) as any;
      for(let t of testsamples) if(t.date_completed) t.date_completed = (t.date_completed as any).substr(0, 16).replace('T', ' ') as any;
      let sample_ids = [...new Set(testsamples.map(x => x['samples_id']))];
      if(sample_ids.length == 0){
        resolve([]);
      }else{
        let samples: SampleModel[] = await this.get('samples', {id: {value: sample_ids}}, 0) as any;

        let patient_ids = [...new Set(samples.filter(x => x.patient_id != null).map(x => x.patient_id))];
        const patient: PatientModel[] = await this.get('patient', {id: {value: patient_ids}}) as any;
        const patient_documents: any[] = await this.get('patients_type_id', {patient_id: {value: patient_ids}}) as any;
        /*const features: PredictedFeatures[] = await this.get('predicted_features', {samples_id: {value: sample_ids}}, 0) as any;
        */
        for(let s of samples){
          //s.predicted_features = features.filter(x => x['samples_id'] === s.id);
          s.patient_id = patient.find(x => x.id === s.patient_id as any);
          if(s.patient_id) s.patient_id["document"] = patient_documents.find(x => x['patient_id'] == s.patient_id.id);
        }
        /*for(let i = 0; i < testsamples.length; i++){
          testsamples[i].sample = samples.find(x => x.id === testsamples[i]['samples_id']);
        }*/
        for(let t of testsamples)
          t.sample = samples.find(x => x.id == t['samples_id']);
        resolve(testsamples);
      }
    });

  }

  public query(table: string, params?: any): Promise<any[]> {
    return this.get(table, params, 0) as any;
  }

  /** Devices  */
  public insertDevice(obj: DeviceModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'devices', obj, { headers });
  }

  public getDevices(params?: any): Promise<DeviceModel[]> {
    return this.get('devices', params, 0) as any;
  }

  public deleteDevice(params?: any) {
    return this.del('devices', params);
  }

  /** Laboratories  */
  public insertLaboratory(obj: LaboratoryModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'laboratories', obj, { headers });
  }

  public getLaboratories(params?: any, force?: boolean): Promise<LaboratoryModel[]> {
    return new Promise((resolve, reject) => {
      if(!this.catalog['laboratories'] || force){
        this.get('laboratories', params, 0).then(groups => {
          this.catalog['laboratories'] = groups;
          resolve(this.catalog['laboratories']);
        }).catch(err => reject(err));
      }else resolve(this.catalog['laboratories']);
    });
  }

  public deleteLaboratory(params?: any) {
    return this.del('laboratories', params);
  }

  /*Devices-laboratories*/
  public getDevicesLaboratories(params?: any) {
    return this.get('devices_laboratories', params, 0);
  }
  public insertDeviceLaboratory(obj: any) {
    const headers = this.headers;
    return this.http.post(this.url + 'devices_laboratories', obj, { headers });
  }
  public deleteDeviceLaboratory(params?: any) {
    return this.del('devices_laboratories', params);
  }

  /** Roles  */
  public insertRole(obj: PatientModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'roles', obj, { headers });
  }

  public getRoles(params?: any, force?: boolean): Promise<RoleModel[]>{
    let roles: RoleModel[] = [];
    return new Promise((resolve, reject) => {
      if(!this.catalog['roles'] || force){
        this.get('rolespermissions', params, 0).then((rolespermissions: any[]) => {
          for(let r of rolespermissions){
            if(roles.filter(x => x.id == r.id).length === 0){
              let role = new RoleModel();
              role.id = r.id;
              role.name = r.name;
              role.description = r.description;
              role.permissions = [{id: r.permissions_id, name: r.permissions_name}];
              roles.push(role);
            }else{
              const i = roles.findIndex(x => x.id === r.id);
              roles[i].permissions.push({id: r.permissions_id, name: r.permissions_name});
            }
          }
          this.catalog['roles'] = roles;
          resolve(this.catalog['roles']);
        }).catch(err => reject(err));
      }else resolve(this.catalog['roles']);

    });
  }

  public deleteRole(params?: any) {
    return this.del('roles', params);
  }

  /** Roles  */
  public insertPermission(obj: PermissionModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'permissions', obj, { headers });
  }

  public getPermission(params?: any): Promise<PermissionModel[]>{
    return this.get('permissions', params, 0) as any;
  }

  public deletePermission(params?: any) {
    return this.del('permissions', params);
  }

  public insertRolePermissions(obj: any) {
    const headers = this.headers;
    return this.http.post(this.url + 'role_permissions', obj, { headers });
  }

  /** Patients  */
  public insertPatient(obj: PatientModel) {
    const headers = this.headers;
    return this.http.post(this.url + 'patient', obj, { headers });
  }

  public searchFullTest(value: string){
    const headers = this.headers;
    return this.http
      .get(this.url + `searchFullTest?value=${value}`, { headers })
      .toPromise();
  }

  public searchSample(value: string){
    const headers = this.headers;
    return this.http
      .get(this.url + `searchSample?value=${value}`, { headers })
      .toPromise();
  }

  /*public getPatients(params?: any): Promise<PatientModel[]> {
    return this.get('patient', params, 0) as any;
  }*/

  public getPatients(params?: any): Promise<PatientModel[]> {
    return new Promise((resolve, reject) => {
      this.get('patient', params, 0)
        .then(async(patients: PatientModel[]) => {
          let groups: GroupModel[] = await this.getPatientGroups() as any;
          console.log(groups);
          for(let i = 0; i < patients.length; i++){
            patients[i].group = groups.find(x => x.id === patients[i].patient_groups_id);
          }
          resolve(patients);
        })
        .catch(err => reject(err));
    });
  }

  public deletePatient(params?: any) {
    return this.del('patient', params);
  }

  /** account */
  public getAccount(params?: any) {
    return this.get('account', params, 0);
  }

  private get(objectName: string, params?: any, page?: number) {
    const headers = this.headers;
    let query_params = '?';
    if (params) {
      for (let i of Object.keys(params)) {
        if (typeof params[i] != 'object') {
          query_params += `&filterName[]=${i}&filterValue[]=${params[i]}`;
        } else {
          const value = (params[i]['value'] as any[]).join('_');
          const operator = params[i]['operator'] || 'IN';
          query_params += `&filterName[]=${i}&filterValue[]=${value}&filterOperator[]=${operator}`;
        }
      }
    }
    if (page > 0) query_params += `&page=${page}`;
    return this.http
      .get(this.url + `${objectName}${query_params}`, { headers })
      .toPromise();
  }
  private search(objectName: string, params?: any) {
    const headers = this.headers;
    let query_params = '?';
    if (params) {
      for (let i of Object.keys(params)) {
        if (typeof params[i] != 'object') {
          query_params += `&filterName[]=${i}&filterValue[]=${params[i]}`;
        } else {
          const value = params[i]['value'];
          const operator = params[i]['operator'];
          query_params += `&filterName[]=${i}&filterValue[]=${value}&filterOperator[]=${operator}`;
        }
      }
    }
    return this.http
      .get(this.url + `${objectName}${query_params}`, { headers })
      .toPromise();
  }
  private del(objectName: string, params?: any) {
    const headers = this.headers;
    let query_params = '?';
    if (params) {
      for (let i of Object.keys(params)) {
        if (typeof params[i] != 'object') {
          query_params += `&filterName[]=${i}&filterValue[]=${params[i]}`;
        } else {
          const value = params[i]['value'];
          const operator = params[i]['operator'];
          query_params += `&filterName[]=${i}&filterValue[]=${value}&filterOperator[]=${operator}`;
        }
      }
    }
    return this.http
      .delete(this.url + `${objectName}${query_params}`, { headers })
      .toPromise();
  }
  public updateAccount(account: any) {
    const headers = this.headers;
    return this.http.post(this.url + 'account', account, { headers });
  }

  public getTypeID(){
    return this.get('type_id');
  }
  public hashFnv32a(str: string) {
    let i, l, hval = 0x811c9dc5;
    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
  }
  public insertChar(str: string, c: string, position: number){
    return str.substring(0, position) + c + str.substring(position);
  }

  public sendMail(mailContent: string){
    const headers = this.headers;
    return this.http.post(this.url + 'email', {mail: mailContent}, {headers});
  }

  public async sendPositiveEmail(test: TestModel, study: string, subAccount?: string) {

        const mailContent = mimemessage.factory({contentType: 'multipart/mixed',body: []});
        const account: AccountModel = await this.getAccount() as any;
        if(study === 'LFT'){
          mailContent.header('From', `${account.name} <app@vidiia.com>`);
        }else{
          mailContent.header('From', 'Vidiia <app@vidiia.com>');
        }
        mailContent.header('To', test["email"]);
        mailContent.header('Subject', 'Your results are ready');

        const alternateEntity = mimemessage.factory({
            contentType: 'multipart/alternate',
            body: []
        });

        let document = test["number"];
        let hash = Crypto.AES.encrypt(`${document}|${test.bundle}|${test["position"]}|${test.bundle}`, account.name).toString();
        console.log(`${document}|${test.bundle}|${test["position"]}|${test.bundle}`, account.name);
        console.log(hash);
        hash = encodeURI(hash);
        const accountName = subAccount || account.name;
        const link = `https://forms.vidiia.com/${accountName.split(' ').join('-').toLowerCase()}/results?h=${hash}`;
        //const reverted = Crypto.AES.decrypt(sha_secret, 'cignpost');
        //console.log(reverted);
        //var originalText = reverted.toString(Crypto.enc.Utf8);

        //console.log(originalText); // 'my message'
        let mailBody = ''
        if(study != 'LFT') mailBody = this.resultTemplate(link);
        else mailBody = this.resultLFTTemplate(link, test.bundle, subAccount);
        const htmlEntity = mimemessage.factory({
            contentType: 'text/html;charset=utf-8',
            body: mailBody
            });

        alternateEntity.body.push(htmlEntity);
        // alternateEntity.body.push(plainEntity);
        mailContent.body.push(alternateEntity);
        this.sendMail(mailContent.toString()).toPromise()
            .then(async(resp) => {
                //alert('Se envió el consolidado correctamente');
                //await this.toasts.showToast('Patient sucessfully notified');
                //this.updateTestSample({id: testSample.id, notify: true}).subscribe();
                //this.router.navigate(['/products']);
            })
            .catch(async(err) => {
                //await this.toasts.showToast('Can\'t notify patient, please try again later');
                //this.router.navigate(['/products']);
            }
            );
  }

  resultTemplate(link: string){
    return `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office"><head>
            <!-- NAME: SELL PRODUCTS -->
            <!--[if gte mso 15]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
            <![endif]-->
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width ,  initial-scale=1">
            <title>*|MC:SUBJECT|*</title>

        <style type="text/css">
      p{
       margin:10px 0;
       padding:0;
      }
      table{
       border-collapse:collapse;
      }
      h1 , h2 , h3 , h4 , h5 , h6{
       display:block;
       margin:0;
       padding:0;
      }
      img , a img{
       border:0;
       height:auto;
       outline:none;
       text-decoration:none;
      }
      body , #bodyTable , #bodyCell{
       height:100%;
       margin:0;
       padding:0;
       width:100%;
      }
      .mcnPreviewText{
       display:none !important;
      }
      #outlook a{
       padding:0;
      }
      img{
       -ms-interpolation-mode:bicubic;
      }
      table{
       mso-table-lspace:0pt;
       mso-table-rspace:0pt;
      }
      .ReadMsgBody{
       width:100%;
      }
      .ExternalClass{
       width:100%;
      }
      p , a , li , td , blockquote{
       mso-line-height-rule:exactly;
      }
      a[href^=tel] , a[href^=sms]{
       color:inherit;
       cursor:default;
       text-decoration:none;
      }
      p , a , li , td , body , table , blockquote{
       -ms-text-size-adjust:100%;
       -webkit-text-size-adjust:100%;
      }
      .ExternalClass , .ExternalClass p , .ExternalClass td , .ExternalClass div , .ExternalClass span , .ExternalClass font{
       line-height:100%;
      }
      a[x-apple-data-detectors]{
       color:inherit !important;
       text-decoration:none !important;
       font-size:inherit !important;
       font-family:inherit !important;
       font-weight:inherit !important;
       line-height:inherit !important;
      }
      .templateContainer{
       max-width:600px !important;
      }
      a.mcnButton{
       display:block;
      }
      .mcnImage , .mcnRetinaImage{
       vertical-align:bottom;
      }
      .mcnTextContent{
       word-break:break-word;
      }
      .mcnTextContent img{
       height:auto !important;
      }
      .mcnDividerBlock{
       table-layout:fixed !important;
      }
      h1{
       color:#222222;
       font-family:Helvetica;
       font-size:40px;
       font-style:normal;
       font-weight:bold;
       line-height:150%;
       letter-spacing:normal;
       text-align:center;
      }
      h2{
       color:#222222;
       font-family:Helvetica;
       font-size:34px;
       font-style:normal;
       font-weight:bold;
       line-height:150%;
       letter-spacing:normal;
       text-align:left;
      }
      h3{
       color:#444444;
       font-family:Helvetica;
       font-size:22px;
       font-style:normal;
       font-weight:bold;
       line-height:150%;
       letter-spacing:normal;
       text-align:left;
      }
      h4{
       color:#949494;
       font-family:Georgia;
       font-size:20px;
       font-style:italic;
       font-weight:normal;
       line-height:125%;
       letter-spacing:normal;
       text-align:left;
      }
      #templateHeader{
       background-color:#F7F7F7;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:45px;
       padding-bottom:45px;
      }
      .headerContainer{
       background-color:transparent;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:0;
       padding-bottom:0;
      }
      .headerContainer .mcnTextContent , .headerContainer .mcnTextContent p{
       color:#757575;
       font-family:Helvetica;
       font-size:16px;
       line-height:150%;
       text-align:left;
      }
      .headerContainer .mcnTextContent a , .headerContainer .mcnTextContent p a{
       color:#007C89;
       font-weight:normal;
       text-decoration:underline;
      }
      #templateBody{
       background-color:#FFFFFF;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:36px;
       padding-bottom:45px;
      }
      .bodyContainer{
       background-color:transparent;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:0;
       padding-bottom:0;
      }
      .bodyContainer .mcnTextContent , .bodyContainer .mcnTextContent p{
       color:#757575;
       font-family:Helvetica;
       font-size:16px;
       line-height:150%;
       text-align:left;
      }
      .bodyContainer .mcnTextContent a , .bodyContainer .mcnTextContent p a{
       color:#007C89;
       font-weight:normal;
       text-decoration:underline;
      }
      #templateFooter{
       background-color:#333333;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:45px;
       padding-bottom:63px;
      }
      .footerContainer{
       background-color:transparent;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:0;
       padding-bottom:0;
      }
      .footerContainer .mcnTextContent , .footerContainer .mcnTextContent p{
       color:#FFFFFF;
       font-family:Helvetica;
       font-size:12px;
       line-height:150%;
       text-align:center;
      }
      .footerContainer .mcnTextContent a , .footerContainer .mcnTextContent p a{
       color:#FFFFFF;
       font-weight:normal;
       text-decoration:underline;
      }
     @media only screen and (min-width:768px){
      .templateContainer{
       width:600px !important;
      }

    } @media only screen and (max-width: 480px){
      body , table , td , p , a , li , blockquote{
       -webkit-text-size-adjust:none !important;
      }

    } @media only screen and (max-width: 480px){
      body{
       width:100% !important;
       min-width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnRetinaImage{
       max-width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImage{
       width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnCartContainer , .mcnCaptionTopContent , .mcnRecContentContainer , .mcnCaptionBottomContent , .mcnTextContentContainer , .mcnBoxedTextContentContainer , .mcnImageGroupContentContainer , .mcnCaptionLeftTextContentContainer , .mcnCaptionRightTextContentContainer , .mcnCaptionLeftImageContentContainer , .mcnCaptionRightImageContentContainer , .mcnImageCardLeftTextContentContainer , .mcnImageCardRightTextContentContainer , .mcnImageCardLeftImageContentContainer , .mcnImageCardRightImageContentContainer{
       max-width:100% !important;
       width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnBoxedTextContentContainer{
       min-width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageGroupContent{
       padding:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnCaptionLeftContentOuter .mcnTextContent , .mcnCaptionRightContentOuter .mcnTextContent{
       padding-top:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageCardTopImageContent , .mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent , .mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
       padding-top:18px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageCardBottomImageContent{
       padding-bottom:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageGroupBlockInner{
       padding-top:0 !important;
       padding-bottom:0 !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageGroupBlockOuter{
       padding-top:9px !important;
       padding-bottom:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnTextContent , .mcnBoxedTextContentColumn{
       padding-right:18px !important;
       padding-left:18px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageCardLeftImageContent , .mcnImageCardRightImageContent{
       padding-right:18px !important;
       padding-bottom:0 !important;
       padding-left:18px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcpreview-image-uploader{
       display:none !important;
       width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      h1{
       font-size:30px !important;
       line-height:125% !important;
      }

    } @media only screen and (max-width: 480px){
      h2{
       font-size:26px !important;
       line-height:125% !important;
      }

    } @media only screen and (max-width: 480px){
      h3{
       font-size:20px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      h4{
       font-size:18px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnBoxedTextContentContainer .mcnTextContent , .mcnBoxedTextContentContainer .mcnTextContent p{
       font-size:14px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .headerContainer .mcnTextContent , .headerContainer .mcnTextContent p{
       font-size:16px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .bodyContainer .mcnTextContent , .bodyContainer .mcnTextContent p{
       font-size:16px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .footerContainer .mcnTextContent , .footerContainer .mcnTextContent p{
       font-size:14px !important;
       line-height:150% !important;
      }

    }</style></head>
        <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <!--*|IF:MC_PREVIEW_TEXT|*-->
            <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">Your results are ready. Please click the following button to view your test results.</span><!--<![endif]-->
            <!--*|END:IF|*-->
            <center>
                <table id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" width="100%" height="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                    <tbody><tr>
                        <td id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" valign="top" align="center">
                            <!-- BEGIN TEMPLATE // -->
                            <table style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                <tbody><tr>
                                    <td id="templateHeader" data-template-container="" style="background:#F7F7F7 none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #F7F7F7;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 45px;padding-bottom: 45px;" valign="top" align="center">
                                        <!--[if (gte mso 9)|(IE)]>
                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                        <tr>
                                        <td align="center" valign="top" width="600" style="width:600px;">
                                        <![endif]-->
                                        <table class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody><tr>
                                                <td class="headerContainer" style="background:transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;" valign="top"><table class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnImageBlockOuter">
                <tr>
                    <td style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner" valign="top">
                        <table class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                            <tbody><tr>
                                <td class="mcnImageContent" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top">


                                            <img alt="" src="https://forms.vidiia.com/assets/images/lab-medical-expert.png" style="max-width: 800px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage" width="564" align="middle">


                                </td>
                            </tr>
                        </tbody></table>
                    </td>
                </tr>
        </tbody>
    </table><table class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top">
                   <!--[if mso]>
        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
        <tr>
        <![endif]-->

        <!--[if mso]>
        <td valign="top" width="600" style="width:600px;">
        <![endif]-->
                    <table style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnTextContentContainer" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                        <tbody><tr>

                            <td class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;" valign="top">

                                <h1 style="display: block;margin: 0;padding: 0;color: #222222;font-family: Helvetica;font-size: 40px;font-style: normal;font-weight: bold;line-height: 150%;letter-spacing: normal;text-align: center;"><br>
    Your results are ready</h1>

                            </td>
                        </tr>
                    </tbody></table>
        <!--[if mso]>
        </td>
        <![endif]-->

        <!--[if mso]>
        </tr>
        </table>
        <![endif]-->
                </td>
            </tr>
        </tbody>
    </table></td>
                                            </tr>
                                        </tbody></table>
                                        <!--[if (gte mso 9)|(IE)]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]-->
                                    </td>
                                </tr>
                                <tr>
                                    <td id="templateBody" data-template-container="" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 36px;padding-bottom: 45px;" valign="top" align="center">
                                        <!--[if (gte mso 9)|(IE)]>
                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                        <tr>
                                        <td align="center" valign="top" width="600" style="width:600px;">
                                        <![endif]-->
                                        <table class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody><tr>
                                                <td class="bodyContainer" style="background:transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;" valign="top"><table class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top">
                   <!--[if mso]>
        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
        <tr>
        <![endif]-->

        <!--[if mso]>
        <td valign="top" width="600" style="width:600px;">
        <![endif]-->
                    <table style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnTextContentContainer" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                        <tbody><tr>

                            <td class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;" valign="top">

                                <h3 style="display: block;margin: 0;padding: 0;color: #444444;font-family: Helvetica;font-size: 22px;font-style: normal;font-weight: bold;line-height: 150%;letter-spacing: normal;text-align: left;">&nbsp;</h3>

    <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Please click the following button to view your test results. You are going to need your Sample ID.</p>

                            </td>
                        </tr>
                    </tbody></table>
        <!--[if mso]>
        </td>
        <![endif]-->

        <!--[if mso]>
        </tr>
        </table>
        <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table class="mcnButtonBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnButtonBlockOuter">
            <tr>
                <td style="padding-top: 0;padding-right: 18px;padding-bottom: 18px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnButtonBlockInner" valign="top" align="center">
                    <table class="mcnButtonContentContainer" style="border-collapse: separate !important;border-top-left-radius: 3px;border-top-right-radius: 3px;border-bottom-right-radius: 3px;border-bottom-left-radius: 3px;background-color: #009FC7;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td class="mcnButtonContent" style="font-family: Helvetica;font-size: 18px;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="middle" align="center">
                                    <a class="mcnButton " title="View results" href="${link}" target="_blank" style="font-weight: bold;letter-spacing: -0.5px;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;display: block;">View results</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table><table class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table></td>
                                            </tr>
                                        </tbody></table>
                                        <!--[if (gte mso 9)|(IE)]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]-->
                                    </td>
                                </tr>

                            </tbody></table>
                            <!-- // END TEMPLATE -->
                        </td>
                    </tr>
                </tbody></table>
            </center>



    </body></html>`;
  }

  resultLFTTemplate(link: string, testId: string, account: string){
    return `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office"><head>
            <!-- NAME: SELL PRODUCTS -->
            <!--[if gte mso 15]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
            <![endif]-->
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width ,  initial-scale=1">
            <title>*|MC:SUBJECT|*</title>

        <style type="text/css">
      p{
       margin:10px 0;
       padding:0;
      }
      table{
       border-collapse:collapse;
      }
      h1 , h2 , h3 , h4 , h5 , h6{
       display:block;
       margin:0;
       padding:0;
      }
      img , a img{
       border:0;
       height:auto;
       outline:none;
       text-decoration:none;
      }
      body , #bodyTable , #bodyCell{
       height:100%;
       margin:0;
       padding:0;
       width:100%;
      }
      .mcnPreviewText{
       display:none !important;
      }
      #outlook a{
       padding:0;
      }
      img{
       -ms-interpolation-mode:bicubic;
      }
      table{
       mso-table-lspace:0pt;
       mso-table-rspace:0pt;
      }
      .ReadMsgBody{
       width:100%;
      }
      .ExternalClass{
       width:100%;
      }
      p , a , li , td , blockquote{
       mso-line-height-rule:exactly;
      }
      a[href^=tel] , a[href^=sms]{
       color:inherit;
       cursor:default;
       text-decoration:none;
      }
      p , a , li , td , body , table , blockquote{
       -ms-text-size-adjust:100%;
       -webkit-text-size-adjust:100%;
      }
      .ExternalClass , .ExternalClass p , .ExternalClass td , .ExternalClass div , .ExternalClass span , .ExternalClass font{
       line-height:100%;
      }
      a[x-apple-data-detectors]{
       color:inherit !important;
       text-decoration:none !important;
       font-size:inherit !important;
       font-family:inherit !important;
       font-weight:inherit !important;
       line-height:inherit !important;
      }
      .templateContainer{
       max-width:600px !important;
      }
      a.mcnButton{
       display:block;
      }
      .mcnImage , .mcnRetinaImage{
       vertical-align:bottom;
      }
      .mcnTextContent{
       word-break:break-word;
      }
      .mcnTextContent img{
       height:auto !important;
      }
      .mcnDividerBlock{
       table-layout:fixed !important;
      }
      h1{
       color:#222222;
       font-family:Helvetica;
       font-size:40px;
       font-style:normal;
       font-weight:bold;
       line-height:150%;
       letter-spacing:normal;
       text-align:center;
      }
      h2{
       color:#222222;
       font-family:Helvetica;
       font-size:34px;
       font-style:normal;
       font-weight:bold;
       line-height:150%;
       letter-spacing:normal;
       text-align:left;
      }
      h3{
       color:#444444;
       font-family:Helvetica;
       font-size:22px;
       font-style:normal;
       font-weight:bold;
       line-height:150%;
       letter-spacing:normal;
       text-align:left;
      }
      h4{
       color:#949494;
       font-family:Georgia;
       font-size:20px;
       font-style:italic;
       font-weight:normal;
       line-height:125%;
       letter-spacing:normal;
       text-align:left;
      }
      #templateHeader{
       background-color:#F7F7F7;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:45px;
       padding-bottom:45px;
      }
      .headerContainer{
       background-color:transparent;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:0;
       padding-bottom:0;
      }
      .headerContainer .mcnTextContent , .headerContainer .mcnTextContent p{
       color:#757575;
       font-family:Helvetica;
       font-size:16px;
       line-height:150%;
       text-align:left;
      }
      .headerContainer .mcnTextContent a , .headerContainer .mcnTextContent p a{
       color:#007C89;
       font-weight:normal;
       text-decoration:underline;
      }
      #templateBody{
       background-color:#FFFFFF;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:36px;
       padding-bottom:45px;
      }
      .bodyContainer{
       background-color:transparent;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:0;
       padding-bottom:0;
      }
      .bodyContainer .mcnTextContent , .bodyContainer .mcnTextContent p{
       color:#757575;
       font-family:Helvetica;
       font-size:16px;
       line-height:150%;
       text-align:left;
      }
      .bodyContainer .mcnTextContent a , .bodyContainer .mcnTextContent p a{
       color:#007C89;
       font-weight:normal;
       text-decoration:underline;
      }
      #templateFooter{
       background-color:#333333;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:45px;
       padding-bottom:63px;
      }
      .footerContainer{
       background-color:transparent;
       background-image:none;
       background-repeat:no-repeat;
       background-position:center;
       background-size:cover;
       border-top:0;
       border-bottom:0;
       padding-top:0;
       padding-bottom:0;
      }
      .footerContainer .mcnTextContent , .footerContainer .mcnTextContent p{
       color:#FFFFFF;
       font-family:Helvetica;
       font-size:12px;
       line-height:150%;
       text-align:center;
      }
      .footerContainer .mcnTextContent a , .footerContainer .mcnTextContent p a{
       color:#FFFFFF;
       font-weight:normal;
       text-decoration:underline;
      }
     @media only screen and (min-width:768px){
      .templateContainer{
       width:600px !important;
      }

    } @media only screen and (max-width: 480px){
      body , table , td , p , a , li , blockquote{
       -webkit-text-size-adjust:none !important;
      }

    } @media only screen and (max-width: 480px){
      body{
       width:100% !important;
       min-width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnRetinaImage{
       max-width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImage{
       width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnCartContainer , .mcnCaptionTopContent , .mcnRecContentContainer , .mcnCaptionBottomContent , .mcnTextContentContainer , .mcnBoxedTextContentContainer , .mcnImageGroupContentContainer , .mcnCaptionLeftTextContentContainer , .mcnCaptionRightTextContentContainer , .mcnCaptionLeftImageContentContainer , .mcnCaptionRightImageContentContainer , .mcnImageCardLeftTextContentContainer , .mcnImageCardRightTextContentContainer , .mcnImageCardLeftImageContentContainer , .mcnImageCardRightImageContentContainer{
       max-width:100% !important;
       width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnBoxedTextContentContainer{
       min-width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageGroupContent{
       padding:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnCaptionLeftContentOuter .mcnTextContent , .mcnCaptionRightContentOuter .mcnTextContent{
       padding-top:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageCardTopImageContent , .mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent , .mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
       padding-top:18px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageCardBottomImageContent{
       padding-bottom:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageGroupBlockInner{
       padding-top:0 !important;
       padding-bottom:0 !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageGroupBlockOuter{
       padding-top:9px !important;
       padding-bottom:9px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnTextContent , .mcnBoxedTextContentColumn{
       padding-right:18px !important;
       padding-left:18px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnImageCardLeftImageContent , .mcnImageCardRightImageContent{
       padding-right:18px !important;
       padding-bottom:0 !important;
       padding-left:18px !important;
      }

    } @media only screen and (max-width: 480px){
      .mcpreview-image-uploader{
       display:none !important;
       width:100% !important;
      }

    } @media only screen and (max-width: 480px){
      h1{
       font-size:30px !important;
       line-height:125% !important;
      }

    } @media only screen and (max-width: 480px){
      h2{
       font-size:26px !important;
       line-height:125% !important;
      }

    } @media only screen and (max-width: 480px){
      h3{
       font-size:20px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      h4{
       font-size:18px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .mcnBoxedTextContentContainer .mcnTextContent , .mcnBoxedTextContentContainer .mcnTextContent p{
       font-size:14px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .headerContainer .mcnTextContent , .headerContainer .mcnTextContent p{
       font-size:16px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .bodyContainer .mcnTextContent , .bodyContainer .mcnTextContent p{
       font-size:16px !important;
       line-height:150% !important;
      }

    } @media only screen and (max-width: 480px){
      .footerContainer .mcnTextContent , .footerContainer .mcnTextContent p{
       font-size:14px !important;
       line-height:150% !important;
      }

    }</style></head>
        <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
            <!--*|IF:MC_PREVIEW_TEXT|*-->
            <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">Your results are ready. Please click the following button to view your test results.</span><!--<![endif]-->
            <!--*|END:IF|*-->
            <center>
                <table id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" width="100%" height="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                    <tbody><tr>
                        <td id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" valign="top" align="center">
                            <!-- BEGIN TEMPLATE // -->
                            <table style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                                <tbody><tr>
                                    <td id="templateHeader" data-template-container="" style="background:#F7F7F7 none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #F7F7F7;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 45px;padding-bottom: 45px;" valign="top" align="center">
                                        <!--[if (gte mso 9)|(IE)]>
                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                        <tr>
                                        <td align="center" valign="top" width="600" style="width:600px;">
                                        <![endif]-->
                                        <table class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody><tr>
                                                <td class="headerContainer" style="background:transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;" valign="top"><table class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnImageBlockOuter">
                <tr>
                    <td style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner" valign="top">
                        <table class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                            <tbody><tr>
                                <td class="mcnImageContent" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top">


                                            <img alt="" src="https://forms.vidiia.com/assets/images/${account}.png" style="max-width: 800px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage" width="564" align="middle">


                                </td>
                            </tr>
                        </tbody></table>
                    </td>
                </tr>
        </tbody>
    </table><table class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top">
                   <!--[if mso]>
        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
        <tr>
        <![endif]-->

        <!--[if mso]>
        <td valign="top" width="600" style="width:600px;">
        <![endif]-->
                    <table style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnTextContentContainer" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                        <tbody><tr>

                            <td class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;" valign="top">

                                <h1 style="display: block;margin: 0;padding: 0;color: #222222;font-family: Helvetica;font-size: 40px;font-style: normal;font-weight: bold;line-height: 150%;letter-spacing: normal;text-align: center;"><br>
    Your results are ready</h1>

                            </td>
                        </tr>
                    </tbody></table>
        <!--[if mso]>
        </td>
        <![endif]-->

        <!--[if mso]>
        </tr>
        </table>
        <![endif]-->
                </td>
            </tr>
        </tbody>
    </table></td>
                                            </tr>
                                        </tbody></table>
                                        <!--[if (gte mso 9)|(IE)]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]-->
                                    </td>
                                </tr>
                                <tr>
                                    <td id="templateBody" data-template-container="" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 36px;padding-bottom: 45px;" valign="top" align="center">
                                        <!--[if (gte mso 9)|(IE)]>
                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                        <tr>
                                        <td align="center" valign="top" width="600" style="width:600px;">
                                        <![endif]-->
                                        <table class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;" width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                                            <tbody><tr>
                                                <td class="bodyContainer" style="background:transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;" valign="top"><table class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top">
                   <!--[if mso]>
        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
        <tr>
        <![endif]-->

        <!--[if mso]>
        <td valign="top" width="600" style="width:600px;">
        <![endif]-->
                    <table style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnTextContentContainer" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
                        <tbody><tr>

                            <td class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;" valign="top">

                                <h3 style="display: block;margin: 0;padding: 0;color: #444444;font-family: Helvetica;font-size: 22px;font-style: normal;font-weight: bold;line-height: 150%;letter-spacing: normal;text-align: left;">&nbsp;</h3>

    <p style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">Please click the following button to view your test results. You may require your passenger locator number and this Test ID: <b>${testId}</b> to retrieve your results.</p>

                            </td>
                        </tr>
                    </tbody></table>
        <!--[if mso]>
        </td>
        <![endif]-->

        <!--[if mso]>
        </tr>
        </table>
        <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table class="mcnButtonBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnButtonBlockOuter">
            <tr>
                <td style="padding-top: 0;padding-right: 18px;padding-bottom: 18px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnButtonBlockInner" valign="top" align="center">
                    <table class="mcnButtonContentContainer" style="border-collapse: separate !important;border-top-left-radius: 3px;border-top-right-radius: 3px;border-bottom-right-radius: 3px;border-bottom-left-radius: 3px;background-color: #009FC7;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" cellspacing="0" cellpadding="0" border="0">
                        <tbody>
                            <tr>
                                <td class="mcnButtonContent" style="font-family: Helvetica;font-size: 18px;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="middle" align="center">
                                    <a class="mcnButton " title="View results" href="${link}" target="_blank" style="font-weight: bold;letter-spacing: -0.5px;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;display: block;">View results</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table><table class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" cellspacing="0" cellpadding="0" border="0">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table></td>
                                            </tr>
                                        </tbody></table>
                                        <!--[if (gte mso 9)|(IE)]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]-->
                                    </td>
                                </tr>

                            </tbody></table>
                            <!-- // END TEMPLATE -->
                        </td>
                    </tr>
                </tbody></table>
            </center>



    </body></html>`;
  }
}
