import { LaboratoryModel } from './../../../models/laboratory.model';
import { UserModel } from './../../../models/user.model';
import { TestDetailComponent } from './test-detail/test-detail.component';
import { SampleDetailComponent } from './sample-detail/sample-detail.component';
import { TestSampleModel } from './../../../models/test.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TestModel } from './../../../models/test.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DeviceModel } from 'src/app/models/device.model';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { SampleModel } from 'src/app/models/sample.model';

@Component({
  selector: 'app-samples',
  templateUrl: './samples.component.html',
  styleUrls: ['./samples.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SamplesComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tests: TestModel[];
  devices: DeviceModel[] = [];
  laboratories: LaboratoryModel[] = [];
  devicesLabs: any[] = [];
  users: UserModel[] = [];
  dataSource = null;
  columnsToDisplay = ['bundle', 'date_completed', 'user', 'device', 'laboratory'];
  innerDisplayedColumns = ['sample', 'retest', 'result', 'updated', 'patient_id'];
  showingTest = false;
  hasPreloadedData = false;

  //expandedElement: TestModel | null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef) { }

    async ngOnInit() {
      
      this.loadPreSavedData();
      if(this.hasPreloadedData) await this.loadData(false, false);
      await this.downloadNewData();
      this.loadData(this.hasPreloadedData);
      
      
    }

    async loadPreSavedData(){
      let saved = localStorage.getItem(`${this.auth.account}-devices`);
      if(saved) this.devices = JSON.parse(saved) as any;
      saved = localStorage.getItem(`${this.auth.account}-laboratories`);
      if(saved) this.laboratories = JSON.parse(saved) as any;
      saved = localStorage.getItem(`${this.auth.account}-devicesLabs`);
      if(saved) this.devicesLabs = JSON.parse(saved) as any;
      saved = localStorage.getItem(`${this.auth.account}-users`);
      if(saved) this.users = JSON.parse(saved) as any;
      saved = localStorage.getItem(`${this.auth.account}-tests`);
      if(saved){
        this.tests = JSON.parse(saved) as any;
        this.hasPreloadedData = true;
      }
    }    

    async downloadNewData(){
      console.log("Downloading new data...");
      this.devices = await this.api.getDevices();
      this.laboratories = await this.api.getLaboratories();
      this.devicesLabs = await this.api.getDevicesLaboratories() as any;
      this.users = await this.api.getUsers() as any;
      console.log("Data updated...");
      
      localStorage.setItem(`${this.auth.account}-devices`, JSON.stringify(this.devices));
      localStorage.setItem(`${this.auth.account}-laboratories`, JSON.stringify(this.laboratories));
      localStorage.setItem(`${this.auth.account}-devicesLabs`, JSON.stringify(this.devicesLabs));
      localStorage.setItem(`${this.auth.account}-users`, JSON.stringify(this.users));
    }

    async loadData(refresh?: boolean, offline = false){
      if(!offline) this.tests = await this.api.getTests({type: 'LAMP'});
      for(let t of this.tests){
        if(!t.date_completed) t.date_completed = "2022-01-01T00:00:00.000Z"
        t['original_date_completed'] = t.date_completed;
        const date_array: any[] = t.date_completed.split('T');
        const date_items = date_array[0].split('-');
        const time = date_array[1].substring(0, 5);

        t.date_completed = `${date_items[2]}/${date_items[1]}/${date_items[0]} ${time}`;
        const device_id = this.devicesLabs.find(x => x.id_device_laboratory == t.id_device_laboratory);
        if(device_id){
          t.id_device_laboratory = this.devices.find(x => x.id == device_id.devices_id);
          t.id_device_laboratory.name = this.laboratories.find(x => x.id == device_id.laboratories_id).name;
        }
        t.users_id = this.users.find(x => x.id == t.users_id as any);
      }
      if(!offline) localStorage.setItem(`${this.auth.account}-tests`, JSON.stringify(this.tests));
      if(refresh){
        this.dataSource.data = this.tests;
      }else{
        this.dataSource = new MatTableDataSource<TestModel>(this.tests);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'date_completed':
              return new Date(item['original_date_completed']);
            case 'laboratory':
              return item.id_device_laboratory.name;
            case 'user':
              return item.users_id.firstname;
            case 'device':
              return item.id_device_laboratory.serial_number;
            default:
            return item[property];
          }
        };
        this.dataSource.paginator = this.paginator;
      }
    }

    async selectTest(row: TestModel) {
      if(this.showingTest) return;
      /*let index = this.tests.findIndex(x => x.id === row.id);
      if(this.tests[index].samples?.length > 0) return;*/
      this.showingTest = true;
      const dialogRef = this.dialog.open(TestDetailComponent, {
        width: '90%',
        data: {test: row}
      });

      dialogRef.afterClosed().subscribe((accepted) => {
        this.showingTest = false;
        if(accepted){

        }
      });

      /*this.tests[index].samples = await this.api.getTestsSamples({tests_id: row.id});
      this.dataSource.data = this.tests;
      //this.expandedElement = this.expandedElement === row ? null : row;
      console.log('Row clicked: ', row);
      this.cd.detectChanges();*/
    }



    async applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log(filterValue);
      this.tests = await this.api.searchSample(filterValue) as any;
      this.dataSource.data = this.tests;
      for(let t of this.tests) this.selectTest(t);
      /*this.dataSource.filter = filterValue.trim().toLowerCase();*/

    }
}
