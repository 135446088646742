import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class UserGuardService implements CanActivate, CanActivateChild{
    constructor(private  auth: AuthService, private  router: Router){

    }
    canActivate( next: ActivatedRouteSnapshot,
                 state: RouterStateSnapshot){
        return this.auth.getUser().then(async () => {
            return this.auth.isLogged().then((logged) => {
                if(!logged) this.router.navigate(['/login']);
                else{
                    if(state.url == '/home'){
                        /*if(!this.auth.rol.productos) {
                            if(this.auth.rol.reportes) this.router.navigate(['/reports']);
                            else if(this.auth.rol.contactos) this.router.navigate(['/contacts']);
                            else if(this.auth.rol.usuarios) this.router.navigate(['/usuarios']);
                        }*/
                        return true;
                    }/*else if(state.url.indexOf('/product') > -1) return this.auth.rol.productos;
                    else if(state.url.indexOf('/report') > -1) return this.auth.rol.reportes;
                    else if(state.url.indexOf('/contact') > -1) return this.auth.rol.contactos;
                    else if(state.url.indexOf('/user') > -1) return this.auth.rol.usuarios;
                    else if(state.url.indexOf('/basf-product') > -1) return this.auth.rol.catalogos;
                    else if(state.url.indexOf('/drafts') > -1) return this.auth.rol.usuarios || this.auth.rol.contactos; */
                    return logged;
                }
            });
        }, () => {return false;});
    }
    canActivateChild( next: ActivatedRouteSnapshot,
                      state: RouterStateSnapshot){
        return this.canActivate(next, state);
    }
}
