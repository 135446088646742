<mat-sidenav-container class="sidenav-container" fullscreen>
    <!-- side-nav -->

  <mat-sidenav class="sidenav" mode="side" opened *ngIf="isLogged">
    <mat-toolbar color="primary" class="toolbar">VIDIIA</mat-toolbar>

   <!-- Elements list menu -->
      <mat-list class="list-menu">
        <mat-list-item *ngFor="let item of accountLinks">
          <a  *ngIf="subAccount == ''"  color="blue" class="" mat-list-item [routerLink]="item.url"><mat-icon aria-hidden="false" aria-label="{{item.icon}} icon">{{item.icon}}</mat-icon>{{ item.name | uppercase }}</a>
        </mat-list-item>

        <mat-divider></mat-divider>

        <div *ngIf="study != 'LFT'">
          <mat-list-item>
            <span class="sub-category">LAMP Test's data</span>
          </mat-list-item>

          <mat-list-item *ngFor="let item of testLinks">
            <a color="blue" mat-list-item [routerLink]="item.url"><mat-icon aria-hidden="false" aria-label="{{item.icon}} icon">{{item.icon}}</mat-icon>{{ item.name | uppercase  }}</a>
          </mat-list-item>
        </div>
        <div *ngIf="study == 'LFT'">
          <mat-list-item>
            <span class="sub-category">Lateral Flow Tests</span>
          </mat-list-item>

          <mat-list-item *ngFor="let item of lftLinks">
            <a color="blue" mat-list-item [routerLink]="item.url"><mat-icon aria-hidden="false" aria-label="{{item.icon}} icon">{{item.icon}}</mat-icon>{{ item.name | uppercase  }}</a>
          </mat-list-item>
        </div>
        <mat-list-item *ngIf="subAccount == ''" >
          <span class="sub-category">Reports</span>
        </mat-list-item>

        <mat-list-item *ngFor="let item of reportsLinks">
          <a  *ngIf="subAccount == ''" color="blue" mat-list-item [routerLink]="item.url"><mat-icon aria-hidden="false" aria-label="{{item.icon}} icon">{{item.icon}}</mat-icon>{{ item.name | uppercase  }}</a>
        </mat-list-item>

        <mat-list-item *ngIf="subAccount == ''" >
          <span class="sub-category">Analytics</span>
        </mat-list-item>

        <mat-list-item *ngFor="let item of analyticsLinks">
          <a  *ngIf="subAccount == ''" color="blue" mat-list-item [routerLink]="item.url"><mat-icon aria-hidden="false" aria-label="{{item.icon}} icon">{{item.icon}}</mat-icon>{{ item.name | uppercase }}</a>
        </mat-list-item>


        <mat-list-item >
          <a color="blue" mat-list-item href="logout"><mat-icon aria-hidden="false" aria-label="logout icon">logout</mat-icon></a>
        </mat-list-item>

        <mat-list-item class="bottom">
          {{account}}
        </mat-list-item>
        <mat-list-item class="bottom right" (click)="launchChangeLog()">
          {{versionNumber}}
        </mat-list-item>
      </mat-list>
  </mat-sidenav>

  <mat-sidenav-content>

    <mat-toolbar class="toolbar">

    </mat-toolbar>
    <div class="container-page">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>




