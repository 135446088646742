export class LFTObject {
  Utr: string;
  Id: string;
  Confidence: number;
  Result: number;
  Timestamp: string;
  Image: string;
  Reference: string;
  UserResult: number;
  ReviewResult: string;
  Reviewed: boolean;
  Mismatch: boolean;
}
