import { TestSampleModel } from './../../../../models/test.model';
import { TestModel } from './../../../../models/test.model';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { SampleModel } from 'src/app/models/sample.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import * as HighCharts from 'highcharts';
import HC_Exporting from 'highcharts/modules/exporting';
import * as moment from 'moment';

HC_Exporting(HighCharts);

@Component({
  selector: 'app-sample-detail',
  templateUrl: './sample-detail.component.html',
  styleUrls: ['./sample-detail.component.less']
})
export class SampleDetailComponent implements OnInit {
  test: TestModel;
  sample: TestSampleModel;
  test_kit = '';
  positive_features = 0;
  negative_features = 0;
  combined_features = 0;
  lastUpdated = '';
  @ViewChild("chartContainer", {static: true}) chartContainer: ElementRef;

  constructor(
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {test: TestModel, sample: TestSampleModel},
    public dialogRef: MatDialogRef<SampleDetailComponent>) {

      this.test = data.test;
      this.sample = data.sample;
    }

  ngOnInit(){
    // this.form = this.formBuilder
    this.lastUpdated = this.test.date_completed.substr(0, 16).replace('T',' ');
    this.test_kit = this.test.samples[0].sample.tube.replace('+NTC', '');
    if(this.sample.negative){
      this.negative_features = this.sample.negative[this.sample.negative.length - 1] as any;
      this.positive_features = this.sample.positive[this.sample.positive.length - 1] as any;
      this.combined_features = (this.sample.positive[this.sample.positive.length - 1] as any) - (this.sample.negative[this.sample.negative.length - 1] as any);

      this.selectChart('delta');

    }


  }

  selectChart(selectedSeries: string){
      const startDate = moment(this.test.date_started);
      const completedDate = moment(this.test.date_completed);
      const completedDateF = moment(this.test.date_completed).format('L');
      const duration = moment.duration(completedDate.diff(startDate)).asSeconds();
      let positiveSeries = [];
      let negativeSeries = [];
      let combinedSeries = [];
      const step: number = duration / this.test.samples[0].positive.length;
      for(let i = 0; i < this.test.samples[0].positive.length; i++){
        const d = moment(this.test.date_started).startOf('day').add(i * step, 's').toDate();
        if(selectedSeries == 'delta'){
          negativeSeries.push([d, this.test.samples[0].positive[i] as any - (this.test.samples[0].negative[i] as any)]);
          positiveSeries.push([d, this.test.samples[this.test.samples.length - 1].positive[i] as any - (this.test.samples[this.test.samples.length - 1].negative[i] as any)]);
          combinedSeries.push([d, this.sample.positive[i] as any - (this.sample.negative[i] as any)]);
        }else if(selectedSeries == 'positive'){
          negativeSeries.push([d, this.test.samples[0].positive[i]]);
          positiveSeries.push([d, this.test.samples[this.test.samples.length - 1].positive[i]]);
          combinedSeries.push([d, this.sample.positive[i]]);
        }else if(selectedSeries == 'negative'){
          negativeSeries.push([d, this.test.samples[0].negative[i]]);
          positiveSeries.push([d, this.test.samples[this.test.samples.length - 1].negative[i]]);
          combinedSeries.push([d, this.sample.negative[i]]);
        }
      }
      let series = [
        {
          name: `NTC`,
          data: negativeSeries
        },
        {
          name: this.sample.sample.tube,
          data: combinedSeries
        },
        {
          name: `PTC`,
          data: positiveSeries
        },
      ];
      let colors = ['#ee636c', '#50B432', '#f5e15f'];
      HighCharts.setOptions({
          colors: colors
      });
      HighCharts.chart(this.chartContainer.nativeElement,{
        chart: {
          type: 'spline',
          height: '30%'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          title: {
            text: ''
          },
          type: 'datetime',
          labels: {
            formatter: function() {
              //console.log(this.value);
              return HighCharts.dateFormat('%M:%S', positiveSeries[this.value][0])
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          floor: -100,
          ceiling: 100,
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          /*pointFormat: '{point.x:%M:%S}: {point.y:.2f}',*/
          formatter: function() {
            //console.log(this);
            return `<b>${this.series.name}</b><br/><b style="color: ${this.series.color};">⬤</b>&nbsp;`
             + HighCharts.dateFormat('%M:%S', positiveSeries[this.x][0]) + ' ' + this.y
          }
          //shared: true
        },
        legend:{ enabled:true },
        series: series,
        exporting: {
          filename: `Sample ${this.sample.sample.tube} - ${selectedSeries} features - ${completedDateF}`,
          chartOptions: {
              plotOptions: {
                  series: {
                      dataLabels: {
                          enabled: false
                      }
                  }
              }
          },
          /*buttons: {
            contextButton: {
                menuItems: ['viewFullscreen']
            }
          }*/
        },
        plotOptions: {
          series: {
              marker: {
                  enabled: false
              }
          }
        },
        fallbackToExportServer: false
      } as any);
  }

  chartChange(ev: any){
    this.selectChart(ev.value);
    //this.generateChart(ev.detail.value, []);
  }

  cancel(){
    this.dialogRef.close()
  }

}
