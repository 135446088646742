import { GroupModel } from 'src/app/models/group.model';
import { PatientModel, TypeIDModel } from './../../../../models/patient.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { LaboratoryModel } from 'src/app/models/laboratory.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.less']
})
export class AddPatientComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    id: [''  ],
    firstname: ['', [Validators.required] ],
    surname: ['', [Validators.required] ],
    address1: [''  ],
    address2: [''  ],
    country: [''  ],
    postcode: [''  ],
    town: [''  ],
    email: ['', [Validators.required, Validators.email]],
    mobile_number: ['', [Validators.required, Validators.pattern('^\\+?[0-9]{1,12}]?$')]],
    parent_name: [''  ],
    parent_email: ['', [Validators.email]],
    parent_mobile_number: ['', [Validators.pattern('^\\+?[0-9]{1,12}]?$')]],
    status: [''],
    staff_id: [''],
    student_id: [''],
    clauses_agreed: [''],
    laboratory_id: ['', [Validators.required] ],
    document_type: [''],
    document_number: [''],
    patient_groups_id: ['', [Validators.required] ],
  });
  groups: GroupModel[] = [];
  selectedDocument = '';
  laboratories: LaboratoryModel[] = [];
  selectedLaboratory: LaboratoryModel;
  type_ids: TypeIDModel[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {
      patient: PatientModel,
      laboratories: LaboratoryModel[],
      groups: GroupModel[]
    },
    public dialogRef: MatDialogRef<AddPatientComponent>) {

      console.log(data);
      this.laboratories = data.laboratories;
      this.groups = data.groups;
      if(data.patient)
        this.elementToForm(data.patient);
    }
  documentChanged(ev: any){
    if(ev.value === 5){
      let hash = this.api.hashFnv32a(ev.value+ (new Date().toString())) + this.api.hashFnv32a((new Date().toString()) + ev.value);
      hash = this.api.insertChar(hash, '-', 10);
      this.f.document_number.setValue(hash);
    }
    this.selectedDocument = (this.type_ids.find(x => x.id === ev.value * 1) as TypeIDModel).type_id;
  }

  async ngOnInit() {
    const settings: any = await this.api.getAccount();
    this.type_ids = await this.api.getTypeID() as any;
    for(let f of settings.disabled_fields)
      delete this.f[f];

  }

  get f() { return this.form.controls; }

  private async elementToForm(el: PatientModel){
    for(let k of Object.keys(this.f))
        if(this.f[k]) this.f[k].setValue(el[k]);
    let lab: any =  await this.api.query('patient_laboratories', {patient_id: el.id});
    if(lab.length > 0) lab = lab[0].laboratories_id;
    let doc: any = await this.api.query('patients_type_id', {patient_id: el.id});
    if(doc.length > 0) doc = doc[0];
    this.f.laboratory_id.setValue(lab);
    this.f.document_number.setValue(doc?.number);
    this.f.document_type.setValue(doc?.type_id_id);
  }

  addElement(){
    if( this.form.invalid){
      return;
    }
    console.log(this.form.value)

    this.api.insertPatient(this.form.value).subscribe((resp: ApiResponseModel) => {
        console.log('respUser', resp);
        this.dialogRef.close(true)
      });
  }

  cancel(){
    this.dialogRef.close()
  }

  isChecked(p: GroupModel){
    return this.f.patient_groups_id.value === p.id;
  }

  check(p: GroupModel){
    return this.f.patient_groups_id.setValue(p.id);
  }


}

