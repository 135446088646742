
<div class="">
  <nav mat-tab-nav-bar>
    <a mat-tab-link [active]="true" routerLink="/reports"> LAMP </a>
    <a mat-tab-link routerLink="/lft/reports"> LFT </a>
  </nav>
</div>
<div class="result-box">
  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner *ngIf="!tests" ></mat-spinner>
  </div>
  <div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" *ngIf="tests">
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [formControl]="startDate" (dateChange)="dateChanged()">
        <input matEndDate placeholder="End date" [formControl]="endDate" (dateChange)="dateChanged()">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="loadData(true)">Load</button>
  </div>
  <div class="overflow" *ngIf="tests" >


    <table #innerTables mat-table #innerSort="matSort" matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="labcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lab code</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> VHL </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Forename</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.firstname}} </td>
      </ng-container>
      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.surname}} </td>
      </ng-container>
      <ng-container matColumnDef="birthdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Birth date</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.birthdate}} </td>
      </ng-container>
      <ng-container matColumnDef="ethnicity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ethicity</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.ethnicity}} </td>
      </ng-container>
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.gender}} </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.address}} </td>
      </ng-container>
      <ng-container matColumnDef="postcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.postcode}} </td>
      </ng-container>
      <ng-container matColumnDef="lab_report_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lab report date</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.date_collected}} </td>
      </ng-container>
      <ng-container matColumnDef="organismcode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organism code</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.document.number}} </td>
      </ng-container>
      <ng-container matColumnDef="spec_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Specimen number</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.tube}} </td>
      </ng-container>
      <ng-container matColumnDef="spec_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Specimen type</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> Nasal </td>
      </ng-container>
      <ng-container matColumnDef="spec_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Specimen date</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.date_collected}} </td>
      </ng-container>
      <ng-container matColumnDef="test_method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Test method</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> Rapid PCR </td>
      </ng-container>
      <ng-container matColumnDef="contact_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact number</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.mobile_number}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email address</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.email}} </td>
      </ng-container>


      <ng-container matColumnDef="ia_country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_country</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> UK </td>
      </ng-container>
      <ng-container matColumnDef="ia_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_date</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.arrival_date}} </td>
      </ng-container>
      <ng-container matColumnDef="ia_dep">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_dep</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.arrival_date}} </td>
      </ng-container>
      <ng-container matColumnDef="IA_PSSPRT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>IA_PSSPRT</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.passport_number}} </td>
      </ng-container>
      <ng-container matColumnDef="ia_reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_reference</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> </td>
      </ng-container>
      <ng-container matColumnDef="ia_flight">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_flight</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.transport_number}} </td>
      </ng-container>
      <ng-container matColumnDef="ia_train">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_train</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> </td>
      </ng-container>
      <ng-container matColumnDef="ia_vessel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_vessel</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> </td>
      </ng-container>
      <ng-container matColumnDef="ia_other">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_other</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> </td>
      </ng-container>
      <ng-container matColumnDef="vaccstat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>vaccstat</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.vaccination_status ? 'yes' : 'no'}} </td>
      </ng-container>
      <ng-container matColumnDef="cov2commen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>cov2commen</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.sample.patient_id?.vaccine_type}} </td>
      </ng-container>
      <ng-container matColumnDef="voc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>voc</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> </td>
      </ng-container>
      <ng-container matColumnDef="vui">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>vui</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> </td>
      </ng-container>
      <ng-container matColumnDef="ia_testtype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ia_testtype</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> Green-2DT </td>
      </ng-container>
      <ng-container matColumnDef="lab_comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>lab_comments</th>
        <td mat-cell *matCellDef="let element" (click)="selectSample(element, element.sample)"> {{element.result}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="40px">
    <button *ngIf="dataSource"  mat-stroked-button color="primary" (click)="exporter.exportTable('xlsx')">Export / Download</button>
  </div>
</div>
