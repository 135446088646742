import { PermissionModel } from './../../../../../models/permission.model';
import { RoleModel } from './../../../../../models/role.model';
import { UserModel } from './../../../../../models/user.model';
import { DeviceModel } from './../../../../../models/device.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.less']
})
export class AddRoleComponent implements OnInit {

  form: FormGroup = this.formBuilder.group({
    id: [''],
    name: ['', [Validators.required] ],
    description: [''],
  });
  roles: RoleModel[] = [];
  selectedPermissions: PermissionModel[] = [];
  permissions: PermissionModel[] = [];
  mailDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {role: RoleModel, permissions: PermissionModel[]},
    public dialogRef: MatDialogRef<AddRoleComponent>) {
      this.permissions = data.permissions || [];
      if(data.role)
        this.elementToForm(data.role);
    }

  async ngOnInit(){
    // this.form = this.formBuilder
  }

  get f() { return this.form.controls; }

  private async elementToForm(el: any){
    this.selectedPermissions = el.permissions.filter(x => x.id > 0);
    for(let k of Object.keys(this.f))
          if(this.f[k]) this.f[k].setValue(el[k]);
  }

  isChecked(p: PermissionModel){
    return this.selectedPermissions.filter(x => x.id === p.id).length > 0;
  }

  check(p: PermissionModel){
    if(!this.isChecked(p)) this.selectedPermissions.push(p);
    else this.selectedPermissions = this.selectedPermissions.filter(x => x.id !== p.id);
  }

  addElement(){
    console.log("addElement")
    if( this.form.invalid){
      return;
    }
    console.log(this.form.value)
   //console.log(this.device)


    this.api.insertRole(this.form.value).subscribe((respUser: ApiResponseModel) => {
        let insertedId = respUser.insertId || this.f.id.value;
        this.api.insertRolePermissions({role_id: insertedId, permissions: this.selectedPermissions.map(x => x.id)}).subscribe(_ => {
          this.dialogRef.close(true)
        });
      });
  }

  cancel(){
    this.dialogRef.close()
  }

}
