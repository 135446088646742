import { LaboratoryModel } from './laboratory.model';
import { GroupModel } from 'src/app/models/group.model';
export class UserModel {
    id: number;
    email: string;
    firstname: string;
    surname: string;
    laboratory_id: LaboratoryModel;
    groups: GroupModel[];
}
