
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";

import { ApiService } from './../../services/api.service';
import {AuthService} from "../../services/auth.service";

import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DeviceModel } from 'src/app/models/device.model';
import { GroupModel } from './../../models/group.model';
import { UserModel } from './../../models/user.model';



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    host: {'class': 'component-wrapper'}
})
export class HomeComponent implements  OnInit{
    loginForm: FormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]]
    });;
    submitted = false;
    logginIn = false;
    keyboardActive = false;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private api: ApiService,
        private auth: AuthService){}

    async ngOnInit() {
      // let user = new UserModel();
      // user.email = 'geral.oe@gmail.com';
      // user.firstname = 'Geraldine';
      // user.surname = 'Macías';

      // let group = new GroupModel();
      // group.name = 'Super Admin';
      console.log("INIT");
      this.auth.getUser().then();

      let device = new DeviceModel();
      device.serial_number = 'ABC123';


      // this.api.insertUser(user).subscribe((respUser: ApiResponseModel) => {
      //   console.log('respUser', respUser);
      // });
        /*
        this.api.insertGroup(group).subscribe((respGroup: ApiResponseModel) => {
          console.log('respGroup', respGroup);
          this.api.insertUserGroup({users_id: respUser.insertId, groups_id: respGroup.insertId}).subscribe(_ => {
            console.log('User group created');
            this.api.deleteUserGroup({users_id: respUser.insertId}).then(_ => {
              console.log('User-group deleted');
              this.api.deleteGroup({id: respGroup.insertId}).then(async _ => {
                console.log('group deleted');
                const group: GroupModel = await this.api.getGroups({id: 3}) as any;
                console.log('group get');
                console.log(group)
                this.api.deleteUser({id: respUser.insertId}).then(_ => console.log('User deleted'));


              });
            });
          });
        });
        */

      // this.api.insertDevice(device).subscribe((respUser: ApiResponseModel) => {
      //   console.log('Device created');
      //   console.log('respUser', respUser);
      // });

      const devices = await this.api.getDevices() as any;
      console.log("Get devices");
      console.log(devices);



      /*this.api.insertAccount({
          "name": "test2",
          "status": "active"
      }).subscribe(resp => console.log(resp));*/
    }
    get f() { return this.loginForm.controls; }

    forgot(){
        if(this.f.email.value === ''){
            alert('Introduce tu correo electrónico');
            return true;
        }
        this.auth.forgotten(this.f.email.value).then(data => {
            this.router.navigate(['/forgot'], {queryParams: {email: this.f.email.value}});
        }).catch(err => {
            alert('No se encontró el email');
        });

    }

    async onSubmit(){
        console.log("SUBMIT");
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.logginIn = true;
        this.auth.login(this.f.email.value, this.f.password.value)
            .then(async (data) => {
                console.log(data);
                this.logginIn = false;
                if(data.challengeName == 'NEW_PASSWORD_REQUIRED'){
                    this.router.navigate(['/recover'], {queryParams: {email: this.f.email.value}});
                }else{
                    this.router.navigate(['/home']);
                }

            })
            .catch(async (data) => {
                console.log(data);
                this.logginIn = false;
                if(data.code === 'UserNotFoundException') data.message = 'El usuario no existe, favor de verificar.';
                else if(data.code === 'NotAuthorizedException') data.message = 'Usuario o contraseña incorrectos.';
                else if(data.code === 'PasswordResetRequiredException') {
                    data.message = 'Debes resetear tu contraseña.';
                    this.router.navigate(['/reset'], {queryParams: {email: this.f.email.value}});
                }
                alert(data.message);
            });
    }
}
