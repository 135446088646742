<div class="result-box">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner *ngIf="loading" ></mat-spinner>
      </div>
      <div class="">
        <div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
          <mat-form-field class="full-width" *ngIf="dataSource"  fxFlex="70" fxLayout="column">
            <span matPrefix><mat-icon color="primary">search</mat-icon></span>
            <mat-label>Search by (passenger locator number, sample id, email...)</mat-label>
            <input matInput (keyup)="searchFilter($event)" [(ngModel)]="searchText" placeholder="Ex. passenger locator number" #input>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" [formControl]="startDate">
              <input matEndDate placeholder="End date" [formControl]="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
          <button mat-stroked-button color="primary" (click)="loadData(true)">Load</button>
        </div>
        <div class="overflow"  >
        <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort>

          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Account </th>
            <td mat-cell *matCellDef="let element"> {{element.account}} </td>
          </ng-container>

          <ng-container matColumnDef="date_created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of creation</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.date_created}} </td>
          </ng-container>
          <ng-container matColumnDef="bundle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sample ID</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.bundle}} </td>
          </ng-container>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activation Code</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.code}} </td>
          </ng-container>
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Passenger locator No</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.number}} </td>
          </ng-container>
          <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.firstname}} </td>
          </ng-container>
          <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Surname</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.surname}} </td>
          </ng-container>
          <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field appearance="fill">
                <mat-label>Patient Result</mat-label>
                <select matNativeControl required (change)="filterChange('result', $event.target.value)">
                  <option value="">all</option>
                  <option value="Positive">Positive</option>
                  <option value="Negative">Negative</option>
                  <option value="Inconclusive">Inconclusive</option>
                </select>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.result}} </td>
          </ng-container>
          <ng-container matColumnDef="ai_result">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field appearance="fill">
                <mat-label>AI Result</mat-label>
                <select matNativeControl required (change)="filterChange('ai_result', $event.target.value)">
                  <option value="">all</option>
                  <option value="Positive">Positive</option>
                  <option value="Negative">Negative</option>
                  <option value="Inconclusive">Inconclusive</option>
                </select>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.ai_result}} </td>
          </ng-container>
          <ng-container matColumnDef="user_result">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field appearance="fill">
                <mat-label>Clinician Result</mat-label>
                <select matNativeControl required (change)="filterChange('user_result', $event.target.value)">
                  <option value="">all</option>
                  <option value="Positive">Positive</option>
                  <option value="Negative">Negative</option>
                  <option value="Inconclusive">Inconclusive</option>
                </select>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.user_result}} </td>
          </ng-container>

          <ng-container matColumnDef="mismatch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field appearance="fill">
                <mat-label>Mismatch</mat-label>
                <select matNativeControl required (change)="filterChange('mismatch', $event.target.value)">
                  <option value="">all</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.mismatch}} </td>
          </ng-container>
          <ng-container matColumnDef="reviewed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header><mat-form-field appearance="fill">
              <mat-label>Review</mat-label>
              <select matNativeControl required (change)="filterChange('reviewed', $event.target.value)">
                <option value="">all</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </mat-form-field></th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.reviewed}} </td>
          </ng-container>
          <ng-container matColumnDef="notified">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <mat-form-field appearance="fill">
                <mat-label>Certificate</mat-label>
                <select matNativeControl required (change)="filterChange('notified', $event.target.value)">
                  <option value="">all</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element, element.sample)"> {{element.notified}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        </table>
        </div>

        <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>

      </div>

</div>
