import { LaboratoryModel } from 'src/app/models/laboratory.model';
import { ConfirmComponent } from './../../../../common/confirm/confirm.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DeviceModel } from 'src/app/models/device.model';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { AddDeviceComponent } from './add-device/add-device.component';
import { AddDevicesInLabsComponent } from './add-devices-in-labs/add-devices-in-labs.component';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.less']
})
export class DevicesComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  devices: DeviceModel[] = [];
  dataSource = null;
  columnsToDisplay: string[];
  devicesLaboratories: any;
  laboratories: LaboratoryModel[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog) { }

    async ngOnInit() {
      await this.loadData();


    }

    async loadData(refresh?: boolean){
      this.laboratories = await this.api.getLaboratories({status: 1});
      this.devicesLaboratories = await this.api.getDevicesLaboratories();

      this.devices = await this.api.getDevices({status:1});
      for(let d of this.devicesLaboratories){
        this.devices.find(x => d.devices_id).laboratory = this.laboratories.find(y => y.id === d.laboratories_id);
      }


      console.log(this.devices);
      this.columnsToDisplay = ['id', 'serial_number', 'laboratory', 'edit', 'delete'];
      if(refresh){
        this.dataSource.data = this.devices;
      }else{
        this.dataSource = new MatTableDataSource<DeviceModel>(this.devices);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }

    selectDevice(row) {
      console.log('Row clicked: ', row);
    }

    deleteDevice(id: number) {
      //change this functions for logic delete
      this.api.deleteDevice({id: id}).then(async _ => {
        console.log("delete device:", id)
      })
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openAddDevice(){
      console.log("openAddDevice");
      const dialogRef = this.dialog.open(AddDeviceComponent, {
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    openAddDeviceToLab(){
      console.log("openAddDevice");
      const dialogRef = this.dialog.open(AddDevicesInLabsComponent, {
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    openEditPatient(p?: DeviceModel) {

      //console.log('editPatient');
      //console.log(p);

      const dialogRef = this.dialog.open(AddDeviceComponent, {
        width: '600px',
        data: {device: p, laboratories: this.laboratories}
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if(result){
          await this.loadData(true);
        }
        //console.log(`Dialog result: ${result}`);
      });
    }

    delete(el: DeviceModel) {

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '300px',
      });

      dialogRef.afterClosed().subscribe((accepted) => {
        if(accepted){
          this.api.insertDevice({'id': el.id, 'status': 0} as any).subscribe(async (_) => {
            console.log('delete device');
            this.loadData(true);
          });
        }
      });

      //update status to 0

    }


}
