import { DownloadableImageComponent } from './modules/accounts/components/downloadable-image/downloadable-image.component';
import { ChangelogComponent } from './modules/accounts/components/changelog/changelog.component';
import { LFTReportsComponent } from './modules/tests-data/reports/lft-reports.component';
import { SetResultComponent } from './common/setResult/set-result.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { LFTTestDetailComponent } from './modules/tests-data/samples/lfttest-detail/lfttest-detail.component';
import { LFTSamplesComponent } from './modules/tests-data/samples/lftsamples.component';
import { LFTPatientsComponent } from './modules/tests-data/patients/lftpatients.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { TestDetailComponent } from './modules/tests-data/samples/test-detail/test-detail.component';
import { AddPatientGroupComponent } from './modules/tests-data/patients/add-group/add-patient-group.component';
import { PatientGroupsComponent } from './modules/tests-data/patients/groups/patient-groups.component';
import { SampleDetailComponent } from './modules/tests-data/samples/sample-detail/sample-detail.component';
import { SamplesTableComponent } from './modules/tests-data/samples/samples-table/samples-table.component';
import { AddPermissionComponent } from './modules/accounts/components/users-permissions/add-permission/add-permission.component';

import { AddGroupComponent } from './modules/accounts/components/users-permissions/add-group/add-group.component';
import { AddRoleComponent } from './modules/accounts/components/users-permissions/add-role/add-role.component';
import { AddUserComponent } from './modules/accounts/components/users-permissions/add-user/add-user.component';
import { GroupsComponent } from './modules/accounts/components/users-permissions/groups/groups.component';
import { RolesComponent } from './modules/accounts/components/users-permissions/roles/roles.component';
import { UsersComponent } from './modules/accounts/components/users-permissions/users/users.component';
import { ConfirmComponent } from './common/confirm/confirm.component';
import { AddPatientComponent } from './modules/tests-data/patients/add-patient/add-patient.component';

import { GetTokenComponent } from './pages/get-token/get-token.component';

import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';

import { ApiService } from './services/api.service';
import {UserGuardService} from './guards/user-guard.service';
import {RootGuardService} from './guards/root-guard.service';
import {AuthService} from './services/auth.service';

import {LoginComponent} from './pages/login/login.component';
import {LogoutComponent} from './pages/login/logout.component';
import {RecoverComponent} from './pages/login/recover.component';
import {ForgottenComponent} from './pages/login/forgotten.component';

import { AppComponent } from './app.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SettingsComponent } from './modules/accounts/components/settings/settings.component';
import { DevicesComponent } from './modules/accounts/components/devices/devices.component';
import { AccountMenuComponent } from './modules/accounts/components/account-menu/account-menu.component';
import { LaboratoriesComponent } from './modules/accounts/components/laboratories/laboratories.component';
import { PatientFormComponent } from './modules/accounts/components/patient-form/patient-form.component';
import { AccountOverviewComponent } from './modules/accounts/components/account-overview/account-overview.component';
import { UsersPermissionsComponent } from './modules/accounts/components/users-permissions/users-permissions.component';
import { NotificationsComponent } from './modules/accounts/components/notifications/notifications.component';
import { HomeComponent } from './pages/login/home.component';
import { PatientsComponent } from './modules/tests-data/patients/patients.component';
import { SamplesComponent } from './modules/tests-data/samples/samples.component';
import { DashboardComponent } from './modules/analytics/dashboard/dashboard.component';
import { AddDeviceComponent } from './modules/accounts/components/devices/add-device/add-device.component';
import { AddLaboratoryComponent } from './modules/accounts/components/laboratories/add-laboratory/add-laboratory.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReportsComponent } from './modules/tests-data/reports/reports.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        GetTokenComponent,
        LogoutComponent,
        RecoverComponent,
        ForgottenComponent,
        AccountMenuComponent,
        SettingsComponent,
        AccountOverviewComponent,
        DevicesComponent,
        LaboratoriesComponent,
        NotificationsComponent,
        UsersPermissionsComponent,
        UsersComponent,
        AddUserComponent,
        RolesComponent,
        AddRoleComponent,
        GroupsComponent,
        AddGroupComponent,
        PatientFormComponent,
        HomeComponent,
        PatientsComponent,
        LFTPatientsComponent,
        SamplesComponent,
        LFTSamplesComponent,
        LFTTestDetailComponent,
        LFTReportsComponent,
        ReportsComponent,
        DashboardComponent,
        AddDeviceComponent,
        AddLaboratoryComponent,
        AddPatientComponent,
        AddPermissionComponent,
        ConfirmComponent,
        SetResultComponent,
        SamplesTableComponent,
        SampleDetailComponent,
        TestDetailComponent,
        PatientGroupsComponent,
        AddPatientGroupComponent,
        ChangelogComponent,
        DownloadableImageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatIconModule,
        MatCheckboxModule,
        MatListModule,
        MatToolbarModule,
        MatCardModule,
        MatTableModule,
        MatTabsModule,
        MatGridListModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        MatButtonModule,
        MatTableExporterModule,
        MatProgressSpinnerModule,
        ColorPickerModule,
        MatDatepickerModule,
        MatNativeDateModule
      ],
      providers: [
        AmplifyService,
        UserGuardService,
        RootGuardService,
        AuthService,
        ApiService,
        {
          provide: MatDialogRef,
          useValue: {}
        },
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
