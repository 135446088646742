import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.css'],
    host: {'class': 'component-wrapper'}
})
export class RecoverComponent implements  OnInit{
    loginForm: FormGroup;
    submitted = false;
    username = '';
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthService){
        this.username = localStorage.getItem('user');
    }
    async ngOnInit() {
        this.loginForm = this.formBuilder.group({
            old_password: ['', [Validators.required, Validators.minLength(8)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            name: ['', Validators.required],
            family_name: ['', Validators.required]
        });
        this.username = this.route.snapshot.queryParams['email'];


    }
    get f() { return this.loginForm.controls; }
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        let that = this;
        let old_password = this.f.old_password.value;
        let password = this.f.password.value;
        let name = this.f.name.value;
        let family_name = this.f.family_name.value;
        this.auth.login(this.username, old_password)
            .then((data) => {
                if(data.challengeName === 'NEW_PASSWORD_REQUIRED'){
                    let user_attributes = data.challengeParam.userAttributes;
                    user_attributes.name = name;
                    user_attributes.family_name = family_name;
                    //user_attributes['custom:country'] = JSON.stringify(countries);
                    delete user_attributes.email_verified;
                    delete user_attributes.phone_number_verified;
                    data.completeNewPasswordChallenge(password, user_attributes, {
                        onSuccess: function(session){
                            that.auth.token = session.idToken.jwtToken;
                            //that.api.setHeaders(that.auth.token);
                            alert('Password updated, please login.');
                            that.auth.logout();

                        },
                        onFailure: function(err){
                            that.router.navigate(['/recover'], {queryParams: {email: that.username}});
                            console.log("Failure",err);
                        }
                    });
                }
                else this.router.navigate(['/home']);

            })
            .catch((data) => alert(data.message));
    }
}