import { MatPaginator } from '@angular/material/paginator';
import { ConfirmComponent } from './../../../../common/confirm/confirm.component';
import { PatientModel } from './../../../../models/patient.model';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LaboratoryModel } from 'src/app/models/laboratory.model';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AddLaboratoryComponent } from './add-laboratory/add-laboratory.component';

@Component({
  selector: 'app-laboratories',
  templateUrl: './laboratories.component.html',
  styleUrls: ['./laboratories.component.less'],
})
export class LaboratoriesComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  laboratories: LaboratoryModel[] = [];
  dataSource = null;
  columnsToDisplay: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog,
  ) {}



  async ngOnInit() {
    await this.loadData();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async loadData(refresh?: boolean){
    this.laboratories = await this.api.getLaboratories({status:1}, true);
    this.columnsToDisplay = ['name', 'region', 'lat', 'lon', 'town', 'created', 'edit', 'delete'];
    if(refresh){
      this.dataSource.data = this.laboratories;
    }else{
      this.dataSource = new MatTableDataSource<LaboratoryModel>(this.laboratories);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  // Open dialog for create laboratory
  openAddLaboratory() {

    const dialogRef = this.dialog.open(AddLaboratoryComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  selectLaboratory(row) {
    console.log('Row clicked: ', row);
  }

  openEdit(el?: LaboratoryModel) {

    console.log('edit');
    console.log(el);

    const dialogRef = this.dialog.open(AddLaboratoryComponent, {
      width: '600px',
      data: {laboratory: el}
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result){
        await this.loadData(true);
      }
      //console.log(`Dialog result: ${result}`);
    });
  }

  deleteLab(lab: LaboratoryModel) {

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe((accepted) => {
      if(accepted){
        this.api.insertLaboratory({'id': lab.id, 'status': 0} as any).subscribe(async (_) => {
          console.log('delete laboratory');
          this.loadData(true);
        });
      }
    });

    //update status to 0

  }
}
