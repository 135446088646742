
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <p> {{question}} </p>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px"></div>


  <br>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
    <button
      mat-raised-button
      color="warn"
      (click)="cancel()">
    Cancel
    </button>

    <button
      mat-raised-button
      (click)="ok()"
      color="primary">

      Ok
    </button>


  </div>


</div>
