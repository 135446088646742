<app-account-menu></app-account-menu>


<div class="result-box">


      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner *ngIf="!dataSource" ></mat-spinner>
      </div>
      <div class="">
        <div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
          <mat-form-field class="full-width" *ngIf="dataSource"  fxFlex="70" fxLayout="column">
            <span matPrefix><mat-icon color="primary">search</mat-icon></span>
            <mat-label>Search by</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. email" #input>
          </mat-form-field>
          <button *ngIf="dataSource"  fxFlex="30" fxLayout="column" mat-raised-button color="primary" (click)="openEditPatient()">Add device</button>
        </div>
        <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort >

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="serial_number">
            <th mat-header-cell *matHeaderCellDef> Serial Number </th>
            <td mat-cell *matCellDef="let element"> {{element.serial_number}} </td>
          </ng-container>

          <ng-container matColumnDef="laboratory">
            <th mat-header-cell *matHeaderCellDef> Laboratory </th>
            <td mat-cell *matCellDef="let element"> {{element.laboratory?.name}} </td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="openEditPatient(element)">
                <mat-icon class="mat-18">mode</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="warn" (click)="delete(element)">
                <mat-icon class="mat-18">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" (click)="selectDevice(myRowData)"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>

      </div>
</div>
