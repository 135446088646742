import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.less']
})
export class AccountOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
