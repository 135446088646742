import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { LaboratoryModel } from 'src/app/models/laboratory.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-laboratory',
  templateUrl: './add-laboratory.component.html',
  styleUrls: ['./add-laboratory.component.less']
})
export class AddLaboratoryComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    id: [''],
    name: ['', [Validators.required] ],
    region: ['', [Validators.required] ],
    lat: ['', [Validators.required, Validators.minLength(4)] ],
    lon: ['', [Validators.required, Validators.minLength(4)] ],
    sub_laboratory: ['', [Validators.required] ],
    address1: [''  ],
    address2: [''  ],
    country: [''  ],
    postcode: [''  ],
    town: [''  ],
    tag: ['', [Validators.required] ],
    status: [1, ],


  })

  constructor(

    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {laboratory: LaboratoryModel},
    public dialogRef: MatDialogRef<AddLaboratoryComponent>) {
      console.log(data);
      if(data.laboratory)
        this.elementToForm(data.laboratory);
    }


  ngOnInit(): void {
  }

  get f() { return this.form.controls; }

  private async elementToForm(el: LaboratoryModel){
    for(let k of Object.keys(this.f))
        if(this.f[k]) this.f[k].setValue(el[k]);
    //this.f.usuario_id.setValue(this.auth.usuario.id);
    //this.f.fecha.setValue(moment(evento.fecha).utcOffset(0).format('YYYY-MM-DDTHH:mm:00-05:00'));
    //this.f.tipo.setValue(evento.tipo.toLowerCase());
    //this.id = evento.id;
  }

  addLaboratory(){
    console.log("addLaboratory")
    console.log(this.f.lat.errors);
    console.log(this.f.lon.errors);
    if( this.form.invalid){
      return;
    }
    console.log(this.form.value)


    this.api.insertLaboratory(this.form.value).subscribe((respUser: ApiResponseModel) => {
        console.log("lab")
        console.log('respUser', respUser);
        this.dialogRef.close(true)
      });
  }

  cancel(){
    this.dialogRef.close()
  }


}
