
import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.less']
})
export class ConfirmComponent implements OnInit {
  question = "Are you sure?";
  constructor(

    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {question: string},
    public dialogRef: MatDialogRef<ConfirmComponent>) {

      if(data) this.question = data.question;

    }



  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close(false)
  }
  ok(){
    this.dialogRef.close(true)
  }

}

