<app-account-menu></app-account-menu>



<mat-card class="general-card result-box">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
      <mat-card-subtitle class="mat-h2 text-center"> Account Details </mat-card-subtitle>
      <mat-icon color="primary" (click)="edit(0)" aria-hidden="false" aria-label="Close">{{editMode[0] ? 'done' : 'mode'}}</mat-icon>
  </mat-card-header>

  <mat-card-content>
    <form autocomplete="off" [formGroup]="form">
    <table>
      <tr *ngFor="let data of personalData|keyvalue" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <td class="text-bold" fxFlex="30"><strong>{{data.key}}</strong></td>
        <td fxFlex="70" *ngIf="!editMode[0]">{{f[data.value[0]].value}}</td>
        <td fxFlex="70" *ngIf="editMode[0]">
          <mat-form-field  fxFlex="100">
            <input matInput
              [value]="data.value[1]"
              [formControlName]="data.value[0]"
              type="text">
            <mat-error *ngIf="f[data.value[0]].hasError('required')">
              Field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
    </table>
    </form>
  </mat-card-content>

  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
      <mat-card-subtitle class="mat-h2">Account Representative Details</mat-card-subtitle>
      <mat-icon color="primary" (click)="edit(1)" aria-hidden="false" aria-label="Close">{{editMode[1] ? 'done' : 'mode'}}</mat-icon>
  </mat-card-header>
  <mat-card-content>
    <form autocomplete="off" [formGroup]="form2">
    <table>
      <tr *ngFor="let data of accountRepresentativeData|keyvalue" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <td class="text-bold" fxFlex="30"><strong>{{data.key}}</strong></td>
        <td fxFlex="70" *ngIf="!editMode[1]">{{f2[data.value[0]].value}}</td>
        <td fxFlex="70" *ngIf="editMode[1]">
          <mat-form-field *ngIf="data.key !== 'links'" fxFlex="100">
            <input matInput
              [value]="data.value[1]"
              [formControlName]="data.value[0]"
              type="text">
            <mat-error *ngIf="f2[data.value[0]].hasError('required')">
              Field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="data.key === 'links'" fxFlex="100">
            <input *ngFor="let v of data.value[1]"  matInput
              [value]="v"
              [formControlName]="data.value[0]"
              type="text">
            <mat-error *ngIf="f2[data.value[0]].hasError('required')">
              Field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </td>
      </tr>
    </table>
    </form>
  </mat-card-content>
  <!--<mat-card-header>
    <mat-card-subtitle class="mat-h2">Account Representative Details</mat-card-subtitle>
  </mat-card-header>-->
</mat-card>
