import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css'],
    host: {'class': 'component-wrapper'}
})
export class LogoutComponent implements  OnInit{
    constructor(
        private router: Router,
        private auth: AuthService){}

    async ngOnInit() {
        console.log("Logout");
        this.auth.logout();
        this.router.navigate(['/login']);
    }
}
