import { ConfirmComponent } from '../../../../common/confirm/confirm.component';
import { GroupModel } from 'src/app/models/group.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { AddPatientGroupComponent } from '../add-group/add-patient-group.component';


@Component({
  selector: 'app-patient-groups',
  templateUrl: './patient-groups.component.html',
  styleUrls: ['./patient-groups.component.less']
})
export class PatientGroupsComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = null;
  columnsToDisplay: string[];
  groups: GroupModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog) { }

    async ngOnInit() {
      await this.loadData();
    }

    async loadData(refresh?: boolean){

      this.groups = await this.api.getPatientGroups({}, true) as any;
      console.log(this.groups);
      this.columnsToDisplay = ['id', 'name', 'tag', 'color', 'edit', 'delete'];
      if(refresh){
        this.dataSource.data = this.groups;
        console.log(this.dataSource.data);
      }else{
        this.dataSource = new MatTableDataSource<GroupModel>(this.groups);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }

    select(row) {
      console.log('Row clicked: ', row);
    }

    deleteDevice(id: number) {
      //change this functions for logic delete
      this.api.deleteDevice({id: id}).then(async _ => {
        console.log("delete device:", id)
      })
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openAddDevice(){
      console.log("openAddDevice");
      const dialogRef = this.dialog.open(AddPatientGroupComponent, {
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    openEdit(el?: GroupModel) {

      //console.log('editPatient');
      //console.log(p);


      const dialogRef = this.dialog.open(AddPatientGroupComponent, {
        width: '600px',
        data: {group: el}
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if(result){
          await this.loadData(true);
        }
        console.log(`Dialog result: ${result}`);
      });
    }

    delete(el: GroupModel) {

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '300px',
      });

      dialogRef.afterClosed().subscribe((accepted) => {
        if(accepted){
          this.api.deletePatientGroup({'id': el.id} as any).then(async (_) => {
            console.log('delete device');
            this.loadData(true);
          });
        }
      });

      //update status to 0

    }


}
