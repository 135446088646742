import { LaboratoryModel } from 'src/app/models/laboratory.model';
import { DeviceModel } from './../../../../../models/device.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.less']
})
export class AddDeviceComponent implements OnInit {
  laboratories: LaboratoryModel[] = [];
  form: FormGroup = this.formBuilder.group({
    id: ['' ],
    serial_number: ['', [Validators.required] ],
  });
  selectedLaboratory = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {device: DeviceModel, laboratories: LaboratoryModel[]},
    public dialogRef: MatDialogRef<AddDeviceComponent>) {
      console.log(data);
      this.laboratories = data.laboratories;
      if(data.device)
        this.elementToForm(data.device);
    }

  ngOnInit(){
    // this.form = this.formBuilder
  }

  get f() { return this.form.controls; }

  private async elementToForm(el: DeviceModel){
    for(let k of Object.keys(this.f))
        if(this.f[k]) this.f[k].setValue(el[k]);
    if(el.laboratory) this.selectedLaboratory = el.laboratory.id;
    //this.f.fecha.setValue(moment(evento.fecha).utcOffset(0).format('YYYY-MM-DDTHH:mm:00-05:00'));
    //this.f.tipo.setValue(evento.tipo.toLowerCase());
    //this.id = evento.id;
  }

  addDevice(){
    console.log("addDevice")
    if( this.form.invalid){
      return;
    }
    console.log(this.form.value)
   //console.log(this.device)


    this.api.insertDevice(this.form.value).subscribe((respUser: ApiResponseModel) => {
        console.log("device")
        console.log('respUser', respUser);
        const deviceId = respUser.insertId || this.f.id.value;
        this.api.deleteDeviceLaboratory({devices_id: deviceId}).then(_ => {
          this.api.insertDeviceLaboratory({devices_id: deviceId, laboratories_id: this.selectedLaboratory})
            .subscribe(_ => this.dialogRef.close(true));
        });


      });
  }

  cancel(){
    this.dialogRef.close()
  }

}
