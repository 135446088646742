import { TestSampleModel } from './../../../models/test.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TestModel } from '../../../models/test.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DeviceModel } from 'src/app/models/device.model';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { SampleModel } from 'src/app/models/sample.model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ReportsComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tests: TestSampleModel[] = null;
  devices: DeviceModel[] = [];
  dataSource = null;
  columnsToDisplay = ['labcode', 'name', 'surname', 'birthdate', 'ethnicity', 'gender', 'address', 'postcode', 'lab_report_date', 'organismcode', 'spec_number', 'spec_type',
              'spec_date', 'test_method', 'contact_number', 'email',  'ia_country', 'ia_date', 'ia_dep', 'IA_PSSPRT', 'ia_reference', 'ia_flight', 'ia_train', 'ia_vessel',
              'ia_other', 'vaccstat', 'cov2commen', 'voc', 'vui', 'ia_testtype', 'lab_comments'];
  startDate = new FormControl(new Date());
  endDate = new FormControl(new Date());
  //expandedElement: TestModel | null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef) { }

    async ngOnInit() {
      const d = new Date();
      d.setDate(d.getDate() - 1);
      this.startDate = new FormControl(d);
      await this.loadData();

    }

    dateChanged(){

    }

    async loadData(refresh?: boolean){
      this.tests = null;
      const start = this.startDate.value.toISOString().slice(0, 10);
      const end = this.endDate.value.toISOString().slice(0, 10);
      this.tests = await this.api.getTestsSamples({type: {value: ['LAMP'], operator: '='}, date_completed: {value: [start, end], operator: 'BETWEEN'}});
      this.tests = this.tests
                    .filter(x => x.sample !== null)
                    .filter(x => x.sample.patient_id);
      console.log(this.tests);
      if(refresh){
        this.dataSource.data = this.tests;
      }else{
        this.dataSource = new MatTableDataSource<TestSampleModel>(this.tests);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }

    async selectTest(row: TestSampleModel) {
      /*let index = this.tests.findIndex(x => x.id === row.id);
      if(this.tests[index].samples?.length > 0) return;*/

      /*const dialogRef = this.dialog.open(TestDetailComponent, {
        width: '90%',
        data: {test: row}
      });

      dialogRef.afterClosed().subscribe((accepted) => {
        if(accepted){

        }
      });*/

      /*this.tests[index].samples = await this.api.getTestsSamples({tests_id: row.id});
      this.dataSource.data = this.tests;
      //this.expandedElement = this.expandedElement === row ? null : row;
      console.log('Row clicked: ', row);
      this.cd.detectChanges();*/
    }



    async applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log(filterValue);
      this.tests = await this.api.searchSample(filterValue) as any;
      this.dataSource.data = this.tests;
      for(let t of this.tests) this.selectTest(t);
      /*this.dataSource.filter = filterValue.trim().toLowerCase();*/

    }
}
