<h1> Laboratory </h1>
<div fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="addLaboratory()">
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field fxFlex="100">
        <mat-label>Laboratory Name</mat-label>
        <input matInput
              formControlName="name"
              type="text"
              placeholder="Laboratory Name">
        <mat-error *ngIf="f.name.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="100">
        <mat-label>Sub laboratory</mat-label>
        <input
        matInput
        formControlName="sub_laboratory"
        type="text"
        placeholder="Sub Laboratory Name">
        <mat-error *ngIf="f.sub_laboratory.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="40px">
    <mat-form-field fxFlex="100">
      <mat-label>Region</mat-label>
      <input
       matInput
       formControlName="region"
       type="text"
       placeholder="Region">
       <mat-error *ngIf="f.region.hasError('required')">
        Field is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="100">
      <mat-label>Latitude</mat-label>
      <input
      matInput
      formControlName="lat"
      type="text"
      placeholder="latitude">
      <mat-error *ngIf="f.lat.hasError('required')">
        Field is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="f.lat.hasError('minlength')">
        Minimum length is {{f.lat.errors.minlength.requiredLength}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="40px">
    <mat-form-field fxFlex="100">
      <mat-label>Longitude</mat-label>
      <input
        matInput
        formControlName="lon"
        type="text"
        placeholder="longitude">
      <mat-error *ngIf="f.lon.hasError('required')">
        Field is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="f.lon.hasError('minlength')">
        Minimum length is {{f.lon.errors.minlength.requiredLength}}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="100">
      <mat-label>Address 1</mat-label>
      <input
      matInput
      formControlName="address1"
      type="text"
      placeholder="address1">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="40px">
    <mat-form-field fxFlex="100">
      <mat-label>Address 2</mat-label>
      <input
        matInput
        formControlName="address2"
        type="text"
        placeholder="address2">
    </mat-form-field>

    <mat-form-field fxFlex="100">
      <mat-label>Country</mat-label>
      <input
        matInput
        formControlName="country"
        type="text"
        placeholder="country">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="40px">
    <mat-form-field fxFlex="100">
      <mat-label>Town</mat-label>
      <input
        matInput
        formControlName="town"
        type="text"
        placeholder="town">
    </mat-form-field>

    <mat-form-field fxFlex="100">
      <mat-label>Tag</mat-label>
      <input
      formControlName="tag"
      matInput
      type="text"
      placeholder="tag">
      <mat-error *ngIf="f.tag.hasError('required')">
        Field is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <br>
  <button
    mat-raised-button
    color="primary">
    Add Laboratory
  </button>

  <button
    mat-raised-button
    color="warn"
    (click)="cancel()">
   Cancel
  </button>
    </form>
</div>
