import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-devices-in-labs',
  templateUrl: './add-devices-in-labs.component.html',
  styleUrls: ['./add-devices-in-labs.component.less']
})
export class AddDevicesInLabsComponent implements OnInit {

  form: FormGroup = this.formBuilder.group({
    serial_number: ['', [Validators.required] ],
  })

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialogRef: MatDialogRef<AddDevicesInLabsComponent>) { }

  ngOnInit(): void {
  }

  addDeviceInLab(){
    console.log("addDevice")
    if( this.form.invalid){
      return;
    }
    console.log(this.form.value)
   //console.log(this.device)


    // this.api.insertDevice(this.form.value).subscribe((respUser: ApiResponseModel) => {
    //     console.log("device")
    //     console.log('respUser', respUser);
    //     this.dialogRef.close(true)
    //   });
  }

  cancel(){
    this.dialogRef.close()
  }

}
