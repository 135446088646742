
import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-set-result',
  templateUrl: './set-result.component.html',
  styleUrls: ['./set-result.component.less']
})
export class SetResultComponent implements OnInit {
  constructor(

    private router: Router,
    private api: ApiService,
    private auth: AuthService,

    public dialogRef: MatDialogRef<any>) {

    }


  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close()
  }
  positive(){
    this.dialogRef.close('Positive');
  }
  negative(){
    this.dialogRef.close('Negative');
  }
  inconclusive(){
    this.dialogRef.close('Inconclusive');
  }

}

