<div class="overlay" *ngIf="loading">
  <mat-spinner ></mat-spinner>
  <span>Running AI analysis</span>
</div>
<div class="container" >

  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div fxFlex="33">
      <button mat-raised-button color="" [disabled]="index == 0" (click)="goBack()"><mat-icon class="mat-18" color="primary">arrow_back</mat-icon> Back</button>
    </div>
    <div fxFlex="33" fxLayoutAlign="center center">
      {{index + 1 }} / {{tests.length}}
    </div>
    <div fxFlex="33" fxLayoutAlign="end">
      <button mat-raised-button color="" [disabled]="index == tests.length - 1" (click)="goNext()">Skip <mat-icon class="mat-18" color="primary">arrow_forward</mat-icon></button>
      <mat-icon color="warn" (click)="close()" aria-hidden="false" aria-label="Close">close</mat-icon>
    </div>
  </div>
</div>
<div fxLayout="column" fxLayoutAlign="center center">
  <mat-spinner *ngIf="!imageInputURL" >
  </mat-spinner>
</div>
<div class="container" >
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="40">
      <img [src]="imageInputURL"/>
    </div>
    <div fxFlex="60" class="data-table" fxLayoutGap="10px">
      <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="info">
        <div fxFlex="100">
          <h1 *ngIf="test['tube'] != 'unavailable'">Is the AI correct?</h1>
          <h1 *ngIf="test['tube'] == 'unavailable'">What is the result of this test?</h1>
        </div>

        <div *ngIf="test['tube'] != 'unavailable'" fxFlex="100">
          <div fxFlex="40">AI results interpretation:</div> <div fxFlex="60"><h2 class="no-margin">{{test['ai_result']}}</h2></div>
        </div>
        <div *ngIf="test['tube'] == 'unavailable'" fxFlex="100">
          <div fxFlex="40">User result interpretation:</div> <div fxFlex="60"><h2 class="no-margin">{{test['result']}}</h2></div>
        </div>
        <div fxFlex="40">Date of creation:</div>          <div fxFlex="60" class="gray">{{test['date_created']}}</div>
        <div fxFlex="40">Patient date of birth:</div>     <div fxFlex="60" class="gray">{{test['birthdate']}}</div>
        <div fxFlex="40">LFT ID:</div>                    <div *ngIf="test['tube'] != 'unavailable'"  fxFlex="60" class="gray">{{test['bundle']}}</div>
                                                          <div *ngIf="test['tube'] == 'unavailable'"  fxFlex="60" class="">
                                                            <input class="bordered"
                                                              matInput
                                                              type="text" [(ngModel)]="new_sample_id"
                                                              placeholder="Enter the 6 digits sample id">
                                                          </div>
        <div fxFlex="40"div>Authentication code:</div>    <div fxFlex="60" class="gray">{{test['code']}}</div>
        <div fxFlex="40">Passenger locator no:</div>      <div fxFlex="60" class="gray">{{test['number']}}</div>
        <div fxFlex="40">User result interpretation:</div><div fxFlex="60" class="gray">{{test['result']}}</div>
        <div fxFlex="40">Clinician interpretation:</div><div fxFlex="60" class="gray">{{test['user_result']}}</div>
        <div *ngIf="test['tube'] != 'unavailable'">
          <div fxFlex="50"><button mat-raised-button color="primary" (click)="isCorrect()">Correct</button></div>
          <div fxFlex="50"><button mat-raised-button color="warn" (click)="showDialog()">Incorrect</button></div>
        </div>
        <div *ngIf="test['tube'] == 'unavailable'">
          <div fxFlex="50"><button mat-raised-button color="primary" (click)="setPositiveNegative(1)">Positive</button>&nbsp;</div>
          <div fxFlex="50"><button mat-raised-button color="warn" (click)="setPositiveNegative(-1)">Negative</button>&nbsp;</div>
          <div fxFlex="50"><button mat-raised-button color="accent" (click)="setPositiveNegative(0)">Inconclusive</button></div>
        </div>
      </div>


    </div>
  </div>
</div>
<!-- <table #innerTables mat-table #innerSort="matSort" [dataSource]="test.samples" matSort>
  <ng-container matColumnDef="sample">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sample</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.sample.tube}} </td>
  </ng-container>
  <ng-container matColumnDef="retest">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Retest</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.retest}} </td>
  </ng-container>
  <ng-container matColumnDef="result">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Result</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.result}} </td>
  </ng-container>
  <ng-container matColumnDef="updated">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last updated</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.date_completed}} </td>
  </ng-container>
  <ng-container matColumnDef="patient_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient ID</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.sample.patient_id}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns">
</tr>
  <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;">
</tr>
</table> -->
