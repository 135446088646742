
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <p> Sample details </p>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">Test Group Id: {{test.bundle}}</div>
<div class="container" *ngIf="test.users_id?.firstname"  fxLayout="row" fxLayoutGap="40px">Operator: {{test.users_id.firstname}} {{test.users_id.surname}}</div>
<div fxLayout="column" fxLayoutAlign="center center">
  <mat-spinner *ngIf="!test.samples" ></mat-spinner>
</div>

<hr/>
<mat-dialog-content>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  Input image:<br/>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <downloadable-image [src]="imageInputURL" [isReversed]="true" fileName="Test {{test.bundle}} - input"></downloadable-image>
  <!-- <img class="reversed" [src]="imageInputURL"/> -->
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  Output image:<br/>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <downloadable-image [src]="imageOutputURL" fileName="Test {{test.bundle}} - output"></downloadable-image>
</div>
<mat-form-field appearance="fill">
  <mat-select value="delta" (selectionChange)="chartChange($event)">
    <mat-option value="delta" selected="selected">Delta features</mat-option>
    <mat-option value="positive">Positive features</mat-option>
    <mat-option value="negative">Negative features</mat-option>
  </mat-select>
</mat-form-field>
<div #chartContainer></div>
<table #innerTables mat-table #innerSort="matSort" [dataSource]="test.samples" matSort>
  <ng-container matColumnDef="sample">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sample</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.sample.tube}} </td>
  </ng-container>
  <ng-container matColumnDef="retest">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Retest</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.retest}} </td>
  </ng-container>
  <ng-container matColumnDef="result">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Result</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.result}} </td>
  </ng-container>
  <ng-container matColumnDef="updated">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last updated</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.date_completed}} </td>
  </ng-container>
  <ng-container matColumnDef="patient_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient ID</th>
    <td mat-cell *matCellDef="let element" (click)="selectSample(test, element)"> {{element?.sample.patient_id?.document?.number}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
</table>
</mat-dialog-content>
