<h1> Device In Laboratory </h1>
<div fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">

    <form >
    <!-- <form autocomplete="off" [formGroup]="form" (ngSubmit)="addDeviceInLab()"> -->

      <!-- <div fxLayout="row" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>Serial Number</mat-label>
          <input
            matInput
            formControlName="serial_number"
            type="text"
            placeholder="Serial Number">
        </mat-form-field>


        <br>
        <button mat-raised-button color="primary" [disabled]="form.invalid">
          Add Device
        </button>

        <button mat-raised-button color="warn" (click)="cancel()">
          Cancel
        </button>
      </div> -->

    </form>
  </div>
</div>
