
<div class="">
  <nav mat-tab-nav-bar>
    <a mat-tab-link [active]="true" routerLink="/patients"> Patients </a>
    <a mat-tab-link routerLink="/patients/groups"> Groups </a>
  </nav>
</div>
<div class="result-box">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner *ngIf="!datasource" ></mat-spinner>
      </div>
      <div>
        <div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
          <mat-form-field class="full-width" *ngIf="datasource"  fxFlex="70" fxLayout="column">
            <span matPrefix><mat-icon color="primary">search</mat-icon></span>
            <mat-label>Search by</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. email" #input>
          </mat-form-field>
          <button *ngIf="datasource"  fxFlex="30" fxLayout="column" mat-raised-button color="primary" (click)="openEditPatient()">Add new patient</button>
        </div>
        <table mat-table [dataSource]="datasource" matTableExporter #exporter="matTableExporter" matSort
        [hiddenColumns]="[columnsToDisplay.length - 2, columnsToDisplay.length - 1]" >

          <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Firstname </th>
            <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
          </ng-container>

          <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
            <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef> Groups </th>
            <td mat-cell *matCellDef="let element"> <div  class="group-icon" [style.background]="'#' + element.group?.color"></div> </td>
          </ng-container>

          <ng-container matColumnDef="mobile_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number  </th>
            <td mat-cell *matCellDef="let element"> {{element.mobile_number}} </td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created </th>
            <td mat-cell *matCellDef="let element"> {{element.created_at.substring(0, 10)}} </td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="openEditPatient(element)">
                <mat-icon class="mat-18">mode</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="warn" (click)="delete(element)">
                <mat-icon class="mat-18">delete</mat-icon>
              </button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="40px">
        <button *ngIf="datasource"  mat-stroked-button color="primary" (click)="exporter.exportTable('xlsx')">Export / Download</button>
      </div>
</div>
