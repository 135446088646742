<app-account-menu></app-account-menu>



<mat-card class="general-card result-box">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
      <mat-card-subtitle class="mat-h2 text-center"> Disabled items </mat-card-subtitle>
      <mat-icon color="primary" (click)="edit(0)" aria-hidden="false" aria-label="Close">{{editMode[0] ? 'done' : 'mode'}}</mat-icon>
  </mat-card-header>

  <mat-card-content>
    <form autocomplete="off" [formGroup]="form">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <ul class="no-list">
          <li *ngFor="let p of available_inputs">
          <mat-checkbox
            [disabled]="!editMode[0]"
            [checked]="isChecked(p.name)"
            (change)="check(p.name)">
            {{p.text}}
          </mat-checkbox>
        </li>
        </ul>
      </div>
    </form>
  </mat-card-content>

  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
      <mat-card-subtitle class="mat-h2">Clauses</mat-card-subtitle>
      <mat-icon color="primary" (click)="edit(1)" aria-hidden="false" aria-label="Close">{{editMode[1] ? 'done' : 'mode'}}</mat-icon>
  </mat-card-header>
  <mat-card-content>
    <table>
      <tr><th>Required</th><th>Text</th></tr>
      <tr *ngFor="let c of clauses" >
        <td>
          <mat-checkbox
            [disabled]="!editMode[1]"
            [checked]="c.required"
            (change)="checkClause(c)">
          </mat-checkbox>
        </td>
        <td *ngIf="editMode[1]" ><textarea rows="4" matInput [(ngModel)]="c.text"></textarea></td>
        <td *ngIf="!editMode[1]" >{{c.text}}</td>
      </tr>
      <tr *ngIf="editMode[1]" ><td>
        <button mat-raised-button color="primary" (click)="addClause()">Add clause</button>
      </td></tr>
    </table>
  </mat-card-content>
  <!--<mat-card-header>
    <mat-card-subtitle class="mat-h2">Account Representative Details</mat-card-subtitle>
  </mat-card-header>-->
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
    <mat-card-subtitle class="mat-h2">Future clauses</mat-card-subtitle>
    <mat-icon color="primary" (click)="edit(2)" aria-hidden="false" aria-label="Close">{{editMode[2] ? 'done' : 'mode'}}</mat-icon>
</mat-card-header>
<mat-card-content>
  <table>
    <tr><th>Required</th><th>Text</th></tr>
    <tr *ngFor="let c of futureClauses" >
      <td>
        <mat-checkbox
          [disabled]="!editMode[2]"
          [checked]="c.required"
          (change)="checkClause(c)">
        </mat-checkbox>
      </td>
      <td *ngIf="editMode[2]" ><textarea rows="4" matInput [(ngModel)]="c.text"></textarea></td>
      <td *ngIf="!editMode[2]" >{{c.text}}</td>
    </tr>
    <tr *ngIf="editMode[2]" ><td>
      <button mat-raised-button color="primary" (click)="addFutureClause()">Add clause</button>
    </td></tr>
  </table>
</mat-card-content>
</mat-card>
