import { AmplifyService } from 'aws-amplify-angular';
import { SampleDetailComponent } from './../sample-detail/sample-detail.component';
import { TestSampleModel } from '../../../../models/test.model';
import { TestModel } from '../../../../models/test.model';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { SampleModel } from 'src/app/models/sample.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import * as HighCharts from 'highcharts';
import HC_Exporting from 'highcharts/modules/exporting';
import * as moment from 'moment';

HC_Exporting(HighCharts);

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrls: ['./test-detail.component.less']
})
export class TestDetailComponent implements OnInit {
  test: TestModel;
  imageInputURL = '';
  imageOutputURL = '';
  innerDisplayedColumns = ['sample', 'retest', 'result', 'updated', 'patient_id'];
  @ViewChild("chartContainer", {static: true}) chartContainer: ElementRef;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private amplify: AmplifyService,
    @Inject(MAT_DIALOG_DATA) public data: {test: TestModel, sample: TestSampleModel},
    public dialogRef: MatDialogRef<TestDetailComponent>) {

      this.test = data.test;

    }

  async ngOnInit(){
    // this.form = this.formBuilder
    this.test.samples = await this.api.getTestsSamples({tests_id: this.test.id});
    this.test.samples.sort((a,b) => {return a.position > b.position ? 1 : -1});
    for(let s of this.test.samples){
      s.positive = s.positive ? JSON.parse(s.positive) : [0];
      s.negative = s.negative ? JSON.parse(s.negative) : [0];
      s.combined = s.combined ? JSON.parse(s.combined) : [0];
    }

    this.imageInputURL = await this.amplify.storage().get(`${this.test.bundle}/input.png`)
    this.imageOutputURL = await this.amplify.storage().get(`${this.test.bundle}/output.png`)

    this.selectChart('delta');
    /*const users = await this.api.getUsers({tests_id: this.test.users_id as any});
    const id_device_lab: any[] = await this.api.getDevicesLaboratories({id_device_laboratory: this.test.id_device_laboratory}) as any;
    if(id_device_lab.length > 0) this.test.id_device_laboratory = (await this.api.getDevices({id: id_device_lab[0].devices_id}) as any[])[0];
    if(users) this.test.users_id = users[0];*/
    /*console.log(this.test.samples);
    let orderedSamples = [];
    for(let i = 0; i < 8; i++){
      orderedSamples[i] = this.test.samples.find(x => x.position - 1 === i) || null;
    }
    this.test.samples = orderedSamples;
    console.log(this.test.samples);
    return;*/

  }

  selectChart(selectedSeries: string){
    let series = [];
    let positiveSeries = [];
    let minimumNegative = 0;
    this.test.date_started = this.test.date_started.replace('T',' ').substring(0, 19);
    const startDate = moment(this.test.date_started);
    const completedDate = moment(this.test.date_completed);
    const completedDateF = moment(this.test.date_completed, "DD/MM/YYYY kk:mm");
    let duration = moment.duration(completedDate.diff(startDate)).asSeconds();
    if(!duration) duration = moment.duration(completedDateF.diff(startDate)).asSeconds();
    for(let s of this.test.samples){
      let tube = s.sample.tube.indexOf('PTC') > -1 ? 'PTC' : s.sample.tube.indexOf('NTC') > -1 ? 'NTC' : s.sample.tube;

      let position = s.position === 1 || s.position === 8 ? '' : s.position - 1;
      positiveSeries = [];
      const step: number = duration / s.positive.length;
      //console.log(s.positive.length);
      //console.log(step);

      for(let i = 0; i < s.positive.length; i++){
        const d = moment(this.test.date_started).startOf('day').add(i * step, 's').toDate();
        let newEntry;
        if(selectedSeries == 'delta'){
          newEntry = s.positive[i] as any - (s.negative[i] as any);
        }else if(selectedSeries == 'positive'){
          newEntry = s.positive[i];
        }else if(selectedSeries == 'negative'){
          newEntry = s.negative[i];
        }
        if(newEntry < minimumNegative) minimumNegative = newEntry;
        positiveSeries.push([d, newEntry]);
      }
      series.push({
        name: `${position} ${tube}`,
        data: positiveSeries
      });
    }

    this.generateChart(series, positiveSeries, minimumNegative, selectedSeries);
  }

  generateChart(series: any[], positiveSeries: any[], minimumNegative: number, selectedSeries: string){
    //console.log(series, positiveSeries);
    const completedDate = moment(this.test.date_completed).format('L');
    let colors = ['#6AF9C4', '#50B432', '#ED561B', '#88DF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#f5e15f'];
    colors[series.length - 1] = '#ee636c';
    HighCharts.setOptions({
        colors: colors
    });

    HighCharts.chart(this.chartContainer.nativeElement,{
      chart: {
        type: 'spline',
        height: '75%',
      },
      title: {
        text: '',
        enabled: false
      },
      credits: {
        enabled: false
      },
       xAxis: {
        title: {
          text: ''
        },
        type: 'datetime',
        labels: {
          formatter: function() {
            //console.log(this.value);
            return HighCharts.dateFormat('%M:%S', positiveSeries[this.value][0])
          }
        },
      },
      yAxis: {
        title: {
          text: ''
        },
        floor: minimumNegative,
        ceiling: 100,
      },
      legend: {
        /*align: 'right',
        verticalAlign: 'top',
        x: -10,
        y: 50,
        floating: true,
        layout: 'vertical',*/
      },
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        /*pointFormat: '{point.x:%M:%S}: {point.y:.2f}',*/
        formatter: function() {
          //console.log(this);
          return `<b>${this.series.name}</b><br/><b style="color: ${this.series.color};">⬤</b>&nbsp;`
           + HighCharts.dateFormat('%M:%S', positiveSeries[this.x][0]) + ' ' + this.y
        }
        //shared: true
      },
      series: series,
      exporting: {
        filename: `Test ${this.test.bundle} - ${selectedSeries} features - ${completedDate}`,
        chartOptions: {
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false
                    }
                }
            }
        },
        /*buttons: {
          contextButton: {
              menuItems: ['viewFullscreen']
          }
        }*/
        fallbackToExportServer: false
      },
      plotOptions: {
        series: {
            marker: {
                enabled: false
            }
        }
    },
    } as any);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
  }

  selectSample(test: TestSampleModel, sample: SampleModel){
    const dialogRef = this.dialog.open(SampleDetailComponent, {
      width: '90%',
      data: {sample: sample, test: test}
    });

    dialogRef.afterClosed().subscribe((accepted) => {
      if(accepted){

      }
    });
  }

  chartChange(ev: any){
    this.selectChart(ev.value);
    //this.generateChart(ev.detail.value, []);
  }

  cancel(){
    this.dialogRef.close()
  }

}
