<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <h1> Roles </h1>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">

    <form autocomplete="off" [formGroup]="form" (ngSubmit)="addElement()">
    <!-- <form autocomplete="off" [formGroup]="form" (ngSubmit)="addDevice()"> -->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>Name</mat-label>
          <input
            matInput
            formControlName="name"
            type="text"
            placeholder="name">
          <mat-error *ngIf="f.name.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <mat-label>Description</mat-label>
          <input
            matInput
            formControlName="description"
            type="text"
            placeholder="description">
        </mat-form-field>
      </div>
      <h2> Permissions </h2>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <ul class="no-list">
          <li *ngFor="let p of permissions">
        <mat-checkbox
                  [checked]="isChecked(p)"
                  (change)="check(p)">
        {{p.name}}
        </mat-checkbox>
        </li>
        </ul>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <button mat-raised-button color="warn" (click)="cancel()">
          Cancel
        </button>
        <button mat-raised-button color="primary" [disabled]="form.invalid">
          Save
        </button>


      </div>
    </form>
  </div>
</div>
