<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <h1> Device </h1>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">

    <form autocomplete="off" [formGroup]="form" (ngSubmit)="addDevice()">
    <!-- <form autocomplete="off" [formGroup]="form" (ngSubmit)="addDevice()"> -->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>Serial Number</mat-label>
          <input
            matInput
            formControlName="serial_number"
            type="text"
            placeholder="Serial Number">
          <mat-error *ngIf="f.serial_number.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>

      </div>

    </form>
  </div>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">
  <mat-form-field fxFlex="100">
    <mat-label>Laboratory</mat-label>
    <mat-select [(ngModel)]="selectedLaboratory">
      <mat-option *ngFor="let r of laboratories" [value]="r.id">
        {{r.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
    <button mat-raised-button color="warn" (click)="cancel()">
      Cancel
    </button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="addDevice()">
      Save
    </button>


  </div>
</div>
</div>
