import { GroupModel } from './../../../../../models/group.model';
import { RoleModel } from './../../../../../models/role.model';
import { UserModel } from './../../../../../models/user.model';
import { DeviceModel } from './../../../../../models/device.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.less']
})
export class AddGroupComponent implements OnInit {
  color = '#000000';
  form: FormGroup = this.formBuilder.group({
    id: [''],
    name: ['', [Validators.required] ],
    tag: [''],
    color: ['', [Validators.required] ],
  });
    mailDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {group: GroupModel},
    public dialogRef: MatDialogRef<AddGroupComponent>) {
      console.log(data);
      if(data.group)
        this.elementToForm(data.group);
    }

  async ngOnInit(){
    // this.form = this.formBuilder
  }

  get f() { return this.form.controls; }

  private async elementToForm(el: any){
    for(let k of Object.keys(this.f))
        if(this.f[k]) this.f[k].setValue(el[k]);
    this.color = `#${this.f.color.value}`;
    //this.f.usuario_id.setValue(this.auth.usuario.id);
    //this.f.fecha.setValue(moment(evento.fecha).utcOffset(0).format('YYYY-MM-DDTHH:mm:00-05:00'));
    //this.f.tipo.setValue(evento.tipo.toLowerCase());
    //this.id = evento.id;
  }

  colorChanged(ev: any){
    this.f.color.setValue(ev.replace('#',''));
    this.color = ev;
  }

  addElement(){
    console.log("addElement")
    if( this.form.invalid){
      return;
    }
    console.log(this.form.value)
   //console.log(this.device)


    this.api.insertGroup(this.form.value).subscribe((respUser: ApiResponseModel) => {
        console.log("device")
        console.log('respUser', respUser);
        this.dialogRef.close(true)
      });
  }

  cancel(){
    this.dialogRef.close()
  }

}
