import { Component, ElementRef, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { SampleModel } from 'src/app/models/sample.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import {saveAs} from 'file-saver';

@Component({
  selector: 'downloadable-image',
  templateUrl: './downloadable-image.component.html',
  styleUrls: ['./downloadable-image.component.less']
})
export class DownloadableImageComponent implements OnInit {
  @Input() src: string = ''
  @Input() isReversed: boolean = false;
  @Input() fileName: string = ''
  @ViewChild("image", {static: true}) image: ElementRef;
  imageFailed = false;
  loaded = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private auth: AuthService) {

    }

  async ngOnInit(){

  }

  downloadImage(){
    return new Promise((resolve, reject) => {
      let req = new XMLHttpRequest();
      req.open('GET', this.src, true);
      req.responseType = 'arraybuffer';
      req.onload =  async(e) => {
          let data = new Uint8Array(req.response);
          resolve(data);
          saveAs(new Blob([data], { type: 'application/octet-stream' }), `${this.fileName}.png`);
      };
      req.send();
    });
  }

  imageError(event: any){
    this.imageFailed = true;
  }

  imageLoaded(){
    this.loaded = true;
  }

}
