import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

import { UserModel } from 'src/app/models/user.model';
import { ApiResponseModel} from 'src/app/models/api-response.model';
import { GroupModel } from 'src/app/models/group.model';
import { UserGroupModel } from 'src/app/models/user-group.model';


@Component({
  selector: 'app-users-permissions',
  templateUrl: './users-permissions.component.html',
  styleUrls: ['./users-permissions.component.less']
})
export class UsersPermissionsComponent implements OnInit {
  users: any;
  groups: any;
  dataSource: UserModel[];
  columnsToDisplay: string[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService) { }

    columnsToDisplayUsers: string[];
    columnsToDisplayRoles: string[];
    columnsToDisplayGroups: string[];

    async ngOnInit() {
      console.log("Get Users");
      this.users = await this.api.getUsers();

      console.log(this.users);

      this.columnsToDisplayUsers = [ 'firstname', 'surname','email' ];
      this.columnsToDisplayGroups = [ 'name', 'tag','color' ];



      this.createGroup({hola: "hi"});

      const userGroup: UserGroupModel = await this.api.getUserGroups() as any;
      console.log('users groups get');
      console.log(userGroup)
      this.groups = await this.api.getUserGroups() as any;
      console.log(this.groups)
    }

    createGroup(groupA: any) {
      let group = new GroupModel();
      group.name = 'HJS';
      this.api.insertGroup(group).subscribe((respGroup: ApiResponseModel) => {
        console.log('respGroup', respGroup);
      });
    }
}
