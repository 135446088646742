<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <h1> User </h1>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">

    <form autocomplete="off" [formGroup]="form" (ngSubmit)="addElement()">
    <!-- <form autocomplete="off" [formGroup]="form" (ngSubmit)="addDevice()"> -->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>Name</mat-label>
          <input
            matInput
            formControlName="name"
            type="text"
            placeholder="name">
          <mat-error *ngIf="f.name.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <mat-label>Tag</mat-label>
          <input
            matInput
            formControlName="tag"
            type="text"
            placeholder="tag">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>Color</mat-label>

          <input
            matInput
            [(colorPicker)]="color"
            formControlName="color"
            (colorPickerChange)="colorChanged($event)"
            type="text"
            placeholder="color">
            <div class="group-icon" [style.background]="color" matSuffix></div>
          <mat-error *ngIf="f.color.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <button mat-raised-button color="warn" (click)="cancel()">
          Cancel
        </button>
        <button mat-raised-button color="primary" [disabled]="form.invalid">
          Save
        </button>


      </div>
    </form>
  </div>
</div>
