import { ChangelogModel } from './../../../../models/changelog.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.less']
})
export class ChangelogComponent implements OnInit{

  changes: ChangelogModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    public dialog: MatDialog,
    private auth: AuthService,
    public dialogRef: MatDialogRef<ChangelogComponent>) { }

    async ngOnInit() {


      this.api.getChangelog().then((logs: ChangelogModel[]) => {
        this.changes = logs;
      }).catch();
    }

    cancel(){
      this.dialogRef.close()
    }

}
