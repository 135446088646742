import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatCardModule } from '@angular/material/card';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit {
  editMode = [false, false];
  settings: any;
  personalData: any;
  representative: any;
  accountRepresentativeData: any;
  form: FormGroup = this.formBuilder.group({
    name: [''],
    city: [''],
    tag: [''],
    project_id: [''],
    study: [''],
    postcode: [''],
    country: [''],
    privacyTerms: [''],
    links: ['']
  });
  form2: FormGroup = this.formBuilder.group({
    name: [''],
    surname: [''],
    email: [''],
    mobile_number: [''],
  });
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService) { }

    async ngOnInit() {
      console.log("Get settings");
      //this.settings = await this.api.get('account');
      this.settings = await this.api.getAccount();
      console.log(typeof this.settings);
      console.log(this.settings);
      // const {representative, ...personalData} = this.settings
      // console.log("rep", representative)
      // console.log("pdata", personalData)

      this.personalData = {
        'Account Name': ['name', this.settings.name],//'Sed porttitor lectus nibh.',
        'Project ID': ['project_id', this.settings.project_id],//'A12342',
        'Tag (Region)': ['tag', this.settings.tag],//'North Englanc',
        'Country or region': ['country', this.settings.country],//'UK',
        'Town / City': ['city', this.settings.city],//'Gullford',
        'Study': ['study', this.settings.study],//'Gullford',
        'Post Code': ['postcode', this.settings.postcode],//'YW6 7HG',
        'Privacy terms': ['privacyTerms', this.settings.privacyTerms],
        'Links': ['links', this.settings.links],
      };

      this.accountRepresentativeData = {
        'Name': ['name', this.settings.representative.name],//'Aurore',
        'Surname': ['surname', this.settings.representative.surname],//'Aurore',
        'e-mail address': ['email', this.settings.representative.email],//'a.priore@surrey.co.uk',
        'Mobile number': ['mobile_number', this.settings.representative.mobile_number],//'0718291020'
      }
      for(let d of Object.keys(this.personalData)){
        let field = this.personalData[d][0];
        let val = this.personalData[d][1];
        this.f[field].setValue(val);
      }
      for(let d of Object.keys(this.accountRepresentativeData)){
        let field = this.accountRepresentativeData[d][0];
        let val = this.accountRepresentativeData[d][1];
        this.f2[field].setValue(val);
      }
    }

    get f() { return this.form.controls; }
    get f2() { return this.form2.controls; }

    edit(index: number){
      if(this.editMode[index] === true){
        for(let k of Object.keys(this.form.value)){
          this.settings[k] = this.f[k].value;
        }
        for(let k of Object.keys(this.form2.value)){
          this.settings.representative[k] = this.f2[k].value;
        }
        this.api.updateAccount(this.settings).subscribe(resp => console.log(resp));
        console.log(this.settings);
      }
      this.editMode[index] = !this.editMode[index];

    }



}
