<div>
    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="background"></div>
        <div>
            <div nowrap>
                <div size="8" offset="2">
                    Código de verificación:
                    <div type="text"  color="dark" formControlName="code" name="code"  (keyup.enter)="password.setFocus()"></div>
                    <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
                        <span class="helper-text right-align" *ngIf="f.code.errors.required">Code is required</span>
                    </div>
                </div>
            </div>
            <div nowrap>
                <div size="8" offset="2">
                    <br/>
                    Nuevo password:
                    <div type="password" color="dark" formControlName="password" name="password" #password ></div>

                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <span class="helper-text right-align" *ngIf="f.password.errors.required">Password is required</span>
                    </div>
                </div>
            </div>

            <div nowrap>
                <div size="8" offset="2" class="ion-text-left">
                    <a color="medium">Login</a>
                </div>
            </div>
            <div nowrap>
                <div size="8" offset="2" class="ion-text-center">

                    <p><br/></p>
                    <button color="primary" (click)="onSubmit()" name="action" size="large" >Cambiar password</button>
                </div>
            </div>
        </div>




    </form>
</div>