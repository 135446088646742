
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <p> What is the correct result? </p>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px"></div>


  <br>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
    <button mat-raised-button color="warn" (click)="negative()">Negative</button>

    <button mat-raised-button (click)="positive()" color="primary">Positive</button>
    <button mat-raised-button (click)="inconclusive()" color="accent">Inconclusive</button>

  </div>


</div>
