import { LFTReportsComponent } from './modules/tests-data/reports/lft-reports.component';
import { LFTSamplesComponent } from './modules/tests-data/samples/lftsamples.component';
import { LFTPatientsComponent } from './modules/tests-data/patients/lftpatients.component';
import { PatientGroupsComponent } from './modules/tests-data/patients/groups/patient-groups.component';

import { GroupsComponent } from './modules/accounts/components/users-permissions/groups/groups.component';
import { RolesComponent } from './modules/accounts/components/users-permissions/roles/roles.component';
import { UsersComponent } from './modules/accounts/components/users-permissions/users/users.component';

import { GetTokenComponent } from './pages/get-token/get-token.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {HomeComponent} from './pages/login/home.component';
import {LogoutComponent} from './pages/login/logout.component';
import {RecoverComponent} from './pages/login/recover.component';
import {ForgottenComponent} from './pages/login/forgotten.component';
import {RootGuardService} from './guards/root-guard.service';
import {UserGuardService} from './guards/user-guard.service';
import { SettingsComponent } from './modules/accounts/components/settings/settings.component';
import { DevicesComponent } from './modules/accounts/components/devices/devices.component';
import { UsersPermissionsComponent } from './modules/accounts/components/users-permissions/users-permissions.component';
import { LaboratoriesComponent } from './modules/accounts/components/laboratories/laboratories.component';
import { PatientFormComponent } from './modules/accounts/components/patient-form/patient-form.component';
import { NotificationsComponent } from './modules/accounts/components/notifications/notifications.component';
import { AccountOverviewComponent } from './modules/accounts/components/account-overview/account-overview.component';
import { DashboardComponent } from './modules/analytics/dashboard/dashboard.component';
import { SamplesComponent } from './modules/tests-data/samples/samples.component';
import { PatientsComponent } from './modules/tests-data/patients/patients.component';
import { ReportsComponent } from './modules/tests-data/reports/reports.component';


const routes: Routes = [
    { path: '',   redirectTo: '/login', pathMatch: 'full' },
    // { path: '**', component: PageNotFoundComponent },
    /*{
        path: '',
        redirectTo: 'general',
        pathMatch: 'full',
        canActivate: [RootGuardService]
    },*/
    { path: '',
    redirectTo: 'general',
    pathMatch: 'full' },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [RootGuardService]
    },
    {
      path: 'get-token',
      component: GetTokenComponent
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'account_overview',
        component: AccountOverviewComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'general',
        component: AccountOverviewComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'laboratories',
        component: LaboratoriesComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'devices',
        component: DevicesComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'roles',
        component: RolesComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'patient_form',
        component: PatientFormComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'patients',
        component: PatientsComponent,
        canActivate: [UserGuardService]
    },
    {
      path: 'lft/patients',
      component: LFTPatientsComponent,
      canActivate: [UserGuardService]
    },
    {
      path: 'lft/reports',
      component: LFTReportsComponent,
      canActivate: [UserGuardService]
    },
    {
        path: 'patients/groups',
        component: PatientGroupsComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'samples',
        component: SamplesComponent,
        canActivate: [UserGuardService]
    },
    {
      path: 'lft/samples',
      component: LFTSamplesComponent,
      canActivate: [UserGuardService]
    },
    {
      path: 'reports',
      component: ReportsComponent,
      canActivate: [UserGuardService]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [UserGuardService]
    },
    {
        path: 'recover',
        component: RecoverComponent,
        canActivate: [RootGuardService]
    },
    {
        path: 'forgot',
        component: ForgottenComponent,
        canActivate: [RootGuardService]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
