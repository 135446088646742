import { LaboratoryModel } from './../../../../../models/laboratory.model';
import { GroupModel } from 'src/app/models/group.model';
import { RoleModel } from './../../../../../models/role.model';
import { ConfirmComponent } from './../../../../../common/confirm/confirm.component';
import { AddUserComponent } from './../add-user/add-user.component';
import { UserModel } from './../../../../../models/user.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DeviceModel } from 'src/app/models/device.model';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less']
})
export class UsersComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  users: UserModel[] = [];
  dataSource = null;
  columnsToDisplay: string[];
  roles: RoleModel[] = [];
  groups: GroupModel[] = [];
  laboratories: LaboratoryModel[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog) { }

    async ngOnInit() {
      await this.loadData();
      this.roles = await this.api.getRoles() as any;
      this.groups = await this.api.getGroups() as any;
    }

    async loadData(refresh?: boolean){
      this.laboratories = await this.api.getLaboratories({status: 1});
      this.users = await this.api.getUsers({status:1}) as any;
      console.log(this.users);
      this.columnsToDisplay = ['id', 'email', 'firstname', 'surname', 'groups', 'edit', 'delete'];
      if(refresh){
        this.dataSource.data = this.users;
      }else{
        this.dataSource = new MatTableDataSource<UserModel>(this.users);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }

    select(row) {
      console.log('Row clicked: ', row);
    }

    deleteDevice(id: number) {
      //change this functions for logic delete
      this.api.deleteDevice({id: id}).then(async _ => {
        console.log("delete device:", id)
      })
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openAddDevice(){
      console.log("openAddDevice");
      const dialogRef = this.dialog.open(AddUserComponent, {
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }

    /*openAddDeviceToLab(){
      console.log("openAddDevice");
      const dialogRef = this.dialog.open(AddUsersInLabsComponent, {
        width: '600px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }*/

    openEdit(el?: UserModel) {

      //console.log('editPatient');
      //console.log(p);


      const dialogRef = this.dialog.open(AddUserComponent, {
        width: '600px',
        data: {user: el, roles: this.roles, groups: this.groups, laboratories: this.laboratories}
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if(result){
          await this.loadData(true);
        }
        //console.log(`Dialog result: ${result}`);
      });
    }

    delete(el: UserModel) {

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '300px',
      });

      dialogRef.afterClosed().subscribe((accepted) => {
        if(accepted){
          this.api.insertUser({'id': el.id, 'status': 0} as any).subscribe(async (_) => {
            console.log('delete device');
            this.loadData(true);
          });
        }
      });

      //update status to 0

    }


}
