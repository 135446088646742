<div>

  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>{{titleLogin}}</mat-card-title>
      <mat-card-subtitle>{{subTitleLogin}}</mat-card-subtitle>
    </mat-card-header>

    <form class="form-horizontal ion-margin-top" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-card-content  fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Username" formControlName="email" type="email" >
          <mat-error *ngIf="f.email.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="f.email.hasError('email')">
            Please enter a <strong>valid email</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" formControlName="password" type="password">
          <mat-error *ngIf="f.password.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="f.password.errors">
            Password must be a least <strong>8 characters</strong> long
          </mat-error>
        </mat-form-field>
      </mat-card-content>
      <button mat-raised-button color="primary" class="btn-block">Log in</button>
    </form>
  </mat-card>

</div>
