import { ApiService } from './api.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AmplifyService} from 'aws-amplify-angular';
import {UserModel} from '../models/user.model';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable()
export class AuthService {
    public usuario: UserModel = new UserModel();
    public user: any;
    public account;
    public subAccount;
    public token: string;
    public payload: any;
    public status = '';
    public permissions = [];
    private data: any;
    public connected = true;
    private userLogin = new Subject<string>();
    private userLogout = new Subject<string>();
    userLoggedIn = this.userLogin.asObservable();
    userLoggedOut = this.userLogout.asObservable();

    constructor(private router: Router, private amplify: AmplifyService, private api: ApiService){

    }
    public login:any = (email: string, password: string) => {
        this.status = '';
        this.data = [];

        let promise = new Promise((resolve, reject) => {
            this.amplify.auth().signIn(email, password)
                .then(user => {
                    this.user = user;
                    if(user.challengeName == 'NEW_PASSWORD_REQUIRED'){
                        this.status = user.ChallengeName;
                        this.data = user;
                    }else{
                        this.token = user.signInUserSession.idToken.jwtToken;
                        this.getAccount(user.signInUserSession.idToken.payload['cognito:groups']);
                        //this.api.setHeaders(this.token);
                        this.userLogin.next('login');
                    }
                    resolve(user);
                })
                .catch(err => {
                    this.token = '';
                    if(this.status == 'NEW_PASSWORD_REQUIRED'){
                        resolve(this.data);
                    }else{
                        reject(err);
                    }
                });
        });
        return promise;
    }
    public forgotten = (email: string) => {
        return new Promise((resolve, reject) => {
            this.amplify.auth().forgotPassword(email)
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });

    }
    public changePasswordByCode = (email: string, code: string, password: string) => {
        return new Promise((resolve, reject) => {
            this.amplify.auth().forgotPasswordSubmit(email, code, password)
                .then(data => {
                    console.log("Changed", data);
                    resolve(true);
                })
                .catch(err => {
                    console.log("Error 2", err);
                    reject(false);
                });
        });
    }
    public logout = () => {
        this.token = '';
        this.user = null;
        this.account = '';
        this.subAccount = '';
        this.permissions = [];
        localStorage.clear();
        this.amplify.auth().signOut()
            .then(data => {this.userLogout.next('logout');})
            .catch(err => console.log(err));

    }
    public changePassword:any = (old_password: string, password: string) => {
        let promise = new Promise((resolve, reject) => {
            this.amplify.auth().currentAuthenticatedUser()
                .then(user => {
                    return this.amplify.auth().changePassword(user, old_password, password);
                })
                .catch(err => {
                    reject(err.message);
                });
        });
        return promise;
    }
    public isLogged = () => {
        return this.amplify.auth().currentAuthenticatedUser()
            .then(user => {
              this.getAccount(user.signInUserSession.idToken.payload['cognito:groups']);
              return true;
            })
            .catch(err => {
                // console.log("Error 2", err);
                this.token = '';
                return false;
            });
    }
    public updateUser:any = (attributes: any) => {
        this.amplify.auth().updateUserAttributes(this.user, attributes)
            .then( result => {
                    //console.log(result);
                }
            )
            .catch(err => {
                console.log(err);
            });
    }
    public registerUser: any = async (email: string) => {

        let strongKey = ''; //await this.api.getpassword(email);
        let promise = new Promise((resolve, reject) => {
            this.amplify.auth().signUp({
                username: email,
                password: strongKey,
                attributes: {
                    email: email
                    // other custom attributes
                },
                validationData: []  //optional
            })
                .then(data => resolve(data))
                .catch(err => reject(err) + email);
        });
        return  promise;
    }
    public getUser:any = () => {
        let promise = new Promise((resolve, reject) => {
            let found = false;
            if(this.usuario)
                if(this.usuario.id){
                    const tempUsuario = this.usuario;
                    //console.log("Expiring", moment(this.user.signInUserSession.idToken.payload.exp, 'X'));
                    //console.log("Now", moment());

                    if(!this.connected){
                        found = true;
                        resolve(this.user);
                    }else if(moment(this.user.signInUserSession.idToken.payload.exp, 'X') < moment()){
                        const refreshToken = this.user.signInUserSession.getRefreshToken();
                        this.user.refreshSession(refreshToken, (err, session) => {
                            if(err){
                                reject(err);
                                this.logout();
                            }else{
                                this.user.setSignInUserSession(session);
                                this.token = session.idToken.jwtToken;

                                this.amplify.auth().currentAuthenticatedUser()
                                    .then(async user => {
                                        this.user = user;
                                        this.usuario = tempUsuario;
                                        resolve(this.user);
                                    });

                            }

                        });
                    }else{
                        found = true;
                        resolve(this.user);
                    }

                }

            if(!found) this.amplify.auth().currentAuthenticatedUser()
                .then(async user => {
                    // user.attributes['custom:country'] = JSON.parse(user.attributes['custom:country']);
                    user;

                    const tmp_token = user.signInUserSession.idToken.jwtToken;


                    this.token = user.signInUserSession.idToken.jwtToken;
                    console.log(this.token);
                    this.api.setHeaders(this.token);
                    //this.usuario.pais_id = user.attributes['custom:country'];
                    resolve(user);

                })
                .catch(err => {
                    this.token = '';
                    console.log("Get User Error", err);
                    reject(false);
                });
        });
        return promise;
    }
    private getAccount(groups: string[]){
      const filteredGroups = groups.filter(x => x !== 'admins');
      if(filteredGroups.filter(x => x.indexOf(':') > -1).length > 0){
        this.account = filteredGroups.filter(x => x.indexOf(':') == -1)[0];
        this.subAccount = filteredGroups.filter(x => x.indexOf(':') > -1)[0].split(':')[1];
      }
      else{
        this.account = filteredGroups[0];
        this.subAccount = '';
      }
      console.log(this.account);
      console.log(this.subAccount);
    }
}
