import { LFTObject } from './../../../models/lft_object.model';
import { TestModel } from './../../../models/test.model';
import { LFTTestDetailComponent } from './lfttest-detail/lfttest-detail.component';
import { PatientModel } from 'src/app/models/patient.model';
import { TestDetailComponent } from './test-detail/test-detail.component';
import { SampleDetailComponent } from './sample-detail/sample-detail.component';
import { TestSampleModel } from '../../../models/test.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { DeviceModel } from 'src/app/models/device.model';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { SampleModel } from 'src/app/models/sample.model';

@Component({
  selector: 'app-lftsamples',
  templateUrl: './lftsamples.component.html',
  styleUrls: ['./lftsamples.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class LFTSamplesComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tests: TestModel[];
  devices: DeviceModel[] = [];
  dataSource = null;
  tableFilters = [];
  timeout;
  columnsToDisplay = ['account', 'date_created', 'bundle', 'code', 'number', 'firstname', 'surname', 'result', 'ai_result', 'user_result', 'mismatch', 'reviewed', 'notified'];
  startDate = new FormControl(new Date());
  endDate = new FormControl(new Date());
  searchText = '';
  showingTest = false;
  loading = false;
  //expandedElement: TestModel | null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef) { }

    async ngOnInit() {
      const d = new Date();
      d.setDate(d.getDate() - 1);
      this.startDate = new FormControl(d);
      if(this.auth.account == 'lateralgo'){
        this.api.lft_read = 'https://q8n9wdpwg7.execute-api.eu-west-2.amazonaws.com/test/';
        this.api.lft_read_key = 'WT2emmuFR45OVnkglKWv08pDEmCDl9hi8b9DujWM';
        this.api.lft_read_account = '6022ebeb85bf4e2ebdbf26c87500f03d';
      }
      await this.loadData();
    }

    async loadData(refresh?: boolean){
      this.loading = true;
      this.searchText = '';
      const start = this.startDate.value.toISOString().slice(0, 10) + " 00:00:00";
      const end = this.endDate.value.toISOString().slice(0, 10) + " 23:59:59";
      if(this.auth.subAccount == '') this.tests = await this.api.fullTests({type: {value: ['LFT'], operator: '='}, date_created: {value: [start, end], operator: 'BETWEEN'}});
      else this.tests = await this.api.fullTests({date_created: {value: [start, end], operator: 'BETWEEN'}, account: {value: [this.auth.subAccount], operator: '='}, type: {value: ['LFT'], operator: '='}});
      for(let t of this.tests) {
        console.log(t.date_created);
        let d = t.date_created.substr(0, 10).split('-');
        t.date_created = `${d[2]}/${d[1]}/${d[0]}`;
        console.log(t.date_created);
        if(t['birthdate']){
          let d = t['birthdate'].substr(0, 10).split('-');
          t['birthdate'] = `${d[2]}/${d[1]}/${d[0]}`;
        }

        t['mismatch'] = t['result'] != t['ai_result'] ? 'Yes' : 'No';
        t['reviewed'] = t['reviewed'] ? 'Yes' : 'No';
        t['notified'] = t['notified'] ? 'Yes' : 'No';
        //t['name'] = `${t['firstname']} ${t['surname']}`;
      }
      console.log("this.tests[0]");
      console.log(this.tests);
      this.processTests(this.tests.filter(x => x['tube'] == 'unavailable'));
      if(refresh){
        this.dataSource.data = this.tests;
      }else{
        this.dataSource = new MatTableDataSource<TestModel>(this.tests);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate =
          (data: TestModel, filtersJson: string) => {
              const matchFilter = [];
              const filters = JSON.parse(filtersJson);

              filters.forEach(filter => {
                const val = data[filter.id] === null ? '' : data[filter.id];
                matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
              });
                return matchFilter.every(Boolean);
            };
      }
      this.loading = false;
    }

    async processTests(tests: TestModel[]){

      for(let t of tests){
        if(t['tube'] == 'unavailable'){
          const lftObject = new LFTObject();
          lftObject.Image = t.image;
          const imageInputURL = `https://vidiia-lft-images.s3.eu-west-2.amazonaws.com/${lftObject.Image}`;
          const new_sample_id = t.bundle;

          const reader = new FileReader();
          const res = await fetch(imageInputURL);
          const downloadedImage = await res.blob();
          reader.onload = (e: any) => {
              const image = new Image();
              image.src = e.target.result;

              image.onload = rs => {
                //console.log(e.target.result);
                //data:image/png;base64,
                //data:application/octet-stream;base64
                //.replace(/^data:image\/\w+;base64,/, '');

                const imgBase64Path = (e.target.result as string).replace(/^data:\w+\/\w+(-\w+)*;base64,/, '');
                //console.log(imgBase64Path);
                //console.log(this.test['result']);
                const result = t['result'] == 'Positive' ? 0 : t['result'] == 'Negative' ? 1 : 2;
                this.api.readLFTTest(imgBase64Path, result, t['number']).toPromise().then(resp => {
                  //this.imageData = '';
                  //this.uploading = false;
                  //console.log(resp);
                  const output = resp as any;
                  if(output['utr']){
                    t['_id'] = output['id'];
                    t['bundle'] = output['utr'];
                    t['tube'] = output['utr'];
                    t['test_result_number'] = output['confidence'];
                    t['ai_result'] = output['status'];
                    const index = this.tests.findIndex(x => x.id == t.id);
                    tests[index] = t;
                    this.api.insertSimpleTest({id: t.id, _id: output['id'], bundle: output['utr']} as any).subscribe(resp => {
                      this.api.insertSample({id: t['samples_id'], tube: output['utr']} as any).subscribe(resp => {
                        this.api.insertTestSample({id: t['tests_samples_id'], ai_result: output['status'], test_result_number: output['confidence']} as any).subscribe(resp => {


                        }, err => {});
                      }, err => {});
                    }, err => {});
                    //this.ltf = output;
                    //this.f.sample_id.setValue(output['utr']);
                  }else{
                    //this.f.sample_id.setValue('unavailable');
                    //this.imageData = imgBase64Path;

                  }

                }).catch(_ => {
                  //this.uploading = false;
                  //this.f.sample_id.setValue('unavailable');
                  //this.imageData = imgBase64Path;

                }).finally(() => {
                  //this.imageName = fileInput.target.files[0].name;
                  //this.isLoading = false;

                });

                  //this.isLoading = false;
              };
            };
            reader.readAsDataURL(downloadedImage);


        }
      }
    }

    async selectTest(row: TestModel) {
      if(this.showingTest) return;
      this.showingTest = true;
      let index = this.dataSource.filteredData.findIndex(x => x.id === row.id);
      //if(this.tests[index].samples?.length > 0) return;

      const dialogRef = this.dialog.open(LFTTestDetailComponent, {
        width: '90%',
        data: {test: row, tests: this.dataSource.filteredData, index}
      });

      dialogRef.afterClosed().subscribe((tests: TestModel[]) => {
        this.showingTest = false;
        for(let t of tests){
          const index = this.tests.findIndex(x => x.bundle === t.bundle);
          this.tests[index] = t;
        }
        this.applyFilter();
      });

      /*this.tests[index].samples = await this.api.getTestsSamples({tests_id: row.id});
      this.dataSource.data = this.tests;
      //this.expandedElement = this.expandedElement === row ? null : row;
      console.log('Row clicked: ', row);
      this.cd.detectChanges();*/
    }

    filterChange(target: string, value: string){
      console.log(value);
      this.tableFilters = this.tableFilters.filter(x => x['id'] != target);
      if(value != ''){
        this.tableFilters.push({
          id: target,
          value: value.trim().toLowerCase()
        });
      }

      this.applyFilter();
    }

    async applyFilter() {
      //const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = JSON.stringify(this.tableFilters);
      console.log(this.dataSource.filter);
    }

    async searchFilter(event: any) {
      const searchValue = (event.target as HTMLInputElement).value;
      console.log(searchValue);
      if(this.timeout) clearTimeout(this.timeout);
      if(searchValue == ''){
        this.loadData(true);
        return;
      }
      this.timeout = setTimeout(async() => {
        this.tests = [];
        console.log("Searching: " + searchValue);
        const filterValue = searchValue;
        if(filterValue == '') return;
        this.tests = await this.api.searchFullTest(filterValue) as any;
        this.dataSource.data = this.tests;
      }, 2000);
    }
}
