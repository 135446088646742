
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <p> Sample details </p>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">Test Group Id: {{test.bundle}}</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">Results: {{sample.result}}</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Sample Id: {{sample.sample.tube}}
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Patient Id: {{sample.sample?.patient_id?.document?.number}}
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Test type: LAMP<br/>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Last updated: {{lastUpdated}}
    </div>

  </div>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">
    &nbsp;
  </div>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Predicted positive features: {{positive_features}}
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Predicted negative features: {{negative_features}}
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Predicted delta features: {{combined_features}}
    </div>
  </div>
</div>
<hr/>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Patient name: {{sample.sample.patient_id?.firstname}} {{sample.sample.patient_id?.surname}}<br/>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      VH ID: {{test.id_device_laboratory?.serial_number}}<br/>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Test kit ID: {{test_kit}}<br/>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px" fxFlex="25">
      Operator<br/>
    </div>
  </div>
</div>
<mat-form-field appearance="fill">
  <mat-select value="delta" (selectionChange)="chartChange($event)">
    <mat-option value="delta" selected="selected">Delta features</mat-option>
    <mat-option value="positive">Positive features</mat-option>
    <mat-option value="negative">Negative features</mat-option>
  </mat-select>
</mat-form-field>
<div #chartContainer></div>
