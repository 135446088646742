import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-samples-table',
  templateUrl: './samples-table.component.html',
  styleUrls: ['./samples-table.component.less']
})
export class SamplesTableComponent implements OnInit {
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @Input()
  set data(newData: []) {
    this.dataSource.data = newData;
  }
  dataSource: MatTableDataSource<Element>;


  constructor() {

    }


  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    console.log(this.dataSource.data);
  }


}

