import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    host: {'class': 'component-wrapper'}
})
export class LoginComponent implements  OnInit{
    titleLogin = "Welcome";
    subTitleLogin = "Sign to continue";

    loginForm: FormGroup = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]]
    });

    submitted = false;
    logginIn = false;
    keyboardActive = false;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private auth: AuthService){}

    ngOnInit() {
        console.log("Login");
    }

    get f() { return this.loginForm.controls; }

    forgot(){
        if(this.f.email.value === ''){
            alert('Enter your email');
            return true;
        }
        this.auth.forgotten(this.f.email.value).then(data => {
            this.router.navigate(['/forgot'], {queryParams: {email: this.f.email.value}});
        }).catch(err => {
            alert('Email not found');
        });

    }

    async onSubmit(){
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.logginIn = true;
        this.auth.login(this.f.email.value, this.f.password.value)
            .then(async (data) => {
                console.log(data);
                this.logginIn = false;
                if(data.challengeName == 'NEW_PASSWORD_REQUIRED'){
                    this.router.navigate(['/recover'], {queryParams: {email: this.f.email.value}});
                }else{
                    if(this.auth.subAccount == '') this.router.navigate(['/home']);
                    else this.router.navigate(['/lft/samples']);
                }

            })
            .catch(async (data) => {
                console.log(data);
                this.logginIn = false;
                if(data.code === 'UserNotFoundException') data.message = 'User does not exist.';
                else if(data.code === 'NotAuthorizedException') data.message = 'User or password are wrong.';
                else if(data.code === 'PasswordResetRequiredException') {
                    data.message = 'Please reset your password.';
                    this.router.navigate(['/reset'], {queryParams: {email: this.f.email.value}});
                }
                alert(data.message);
            });
    }
}
