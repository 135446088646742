<div class="result-box">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner *ngIf="!dataSource" ></mat-spinner>
      </div>
      <div class="">
        <div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
          <mat-form-field class="full-width" *ngIf="dataSource"  fxFlex="70" fxLayout="column">
            <span matPrefix><mat-icon color="primary">search</mat-icon></span>
            <mat-label>Search by</mat-label>
            <input matInput (change)="applyFilter($event)" placeholder="Ex. email" #input>
          </mat-form-field>

        </div>

        <table mat-table #outerSort="matSort" [dataSource]="dataSource" multiTemplateDataRows matSort>
          <ng-container matColumnDef="bundle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bundle</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.bundle}} </td>
          </ng-container>
          <ng-container matColumnDef="date_completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date completed</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.date_completed}} </td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.users_id.firstname}} </td>
          </ng-container>
          <ng-container matColumnDef="device">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Device</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.id_device_laboratory.serial_number}} </td>
          </ng-container>
          <ng-container matColumnDef="laboratory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Laboratory</th>
            <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.id_device_laboratory.name}} </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <!-- <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" *ngIf="element.samples?.length" [@detailExpand]="element.samples ? 'expanded' : 'collapsed'">
                <div class="inner-table mat-elevation-z8" >
                  <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                  </mat-form-field>
                  <table #innerTables mat-table #innerSort="matSort" [dataSource]="element.samples" matSort>
                    <ng-container matColumnDef="sample">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sample</th>
                      <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.sample.tube}} </td>
                    </ng-container>
                    <ng-container matColumnDef="retest">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Retest</th>
                      <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.retest}} </td>
                    </ng-container>
                    <ng-container matColumnDef="result">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Result</th>
                      <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.result}} </td>
                    </ng-container>
                    <ng-container matColumnDef="updated">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last updated</th>
                      <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.date_completed}} </td>
                    </ng-container>
                    <ng-container matColumnDef="patient_id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient ID</th>
                      <td mat-cell *matCellDef="let element" (click)="selectTest(element)"> {{element.sample.patient_id?.document?.number}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </td>
          </ng-container> -->

          <!--<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>-->

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
          <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
        </table>


        <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>

      </div>

</div>
