<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <h1> User </h1>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">

    <form autocomplete="off" [formGroup]="form" (ngSubmit)="addElement()">
    <!-- <form autocomplete="off" [formGroup]="form" (ngSubmit)="addDevice()"> -->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>First name</mat-label>
          <input
            matInput
            formControlName="firstname"
            type="text"
            placeholder="First name">
          <mat-error *ngIf="f.firstname.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <mat-label>Surname</mat-label>
          <input
            matInput
            formControlName="surname"
            type="text"
            placeholder="Surname">
          <mat-error *ngIf="f.surname.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>Email</mat-label>
          <input
          matInput
          [readonly]="mailDisabled"
          formControlName="email"
          type="email"
          placeholder="Email">
          <mat-error *ngIf="f.email.hasError('required')">
            Field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="f.email.hasError('email')">
            Please enter a <strong>valid email</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100">
          <mat-label>Role</mat-label>
          <mat-select formControlName="roles_id">
            <mat-option *ngFor="let r of roles" [value]="r.id">
              {{r.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="40px">
        <mat-form-field fxFlex="100">
          <mat-label>Laboratory</mat-label>
          <mat-select formControlName="laboratories_id">
            <mat-option *ngFor="let r of laboratories" [value]="r.id">
              {{r.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <h2> Groups </h2>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <ul class="no-list">
          <li *ngFor="let p of groups">
          <mat-checkbox
            [checked]="isChecked(p)"
            (change)="check(p)">
            {{p.name}} <div class="group-icon" [style.background]="'#' + p.color"></div>
          </mat-checkbox>
        </li>
        </ul>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <button mat-raised-button color="warn" (click)="cancel()">
          Cancel
        </button>
        <button mat-raised-button color="primary" [disabled]="form.invalid">
          Save
        </button>


      </div>
    </form>
  </div>
</div>
