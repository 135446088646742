<div>
    <div class="background-transparent ion-hide-sm-down"></div>
    <form class="form-horizontal ion-margin-top" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="ion-no-padding ion-margin-top full-height">
        <!-- <div class="ion-no-padding ion-no-margin background">
            <div class="ion-margin-top ion-no-padding ion-hide-lg-up top-bar" sizeXs="9" sizeSm="9" sizeMd="9" sizeLg="0" sizeXl="0">
                <div class="ion-no-padding">
                <div size="12" class="blue-bar ion-no-padding">
                    <div class="ion-no-padding" size="6" offset="6">
                        <img routerLink="/" class="ion-float-left" src="/assets/logo.png" />
                    </div>
                </div>
                </div>
            </div>
        </div> -->
        <div class="ion-margin-top">
            <div sizeXs="10" offsetXs="1"
                     sizeSm="9" offsetSm="3"
                     sizeMd="8" offsetMd="4"
                     sizeLg="6" offsetLg="0"
                     sizeXl="6" offsetXl="0" class="ion-no-padding ion-no-margin">



                    <div class="ion-no-padding translucent-background">

                        <div>
                            <div size-Xs="10" offset-Xs="1" size-sm="8" offset-sm="2" size-xl="8" offset-xl="2" class="ion-text-left">
                                <h5 class="lab-login">User:</h5>
                                <div lines="none" class="ion-no-padding" color="transparent">
                                    <input
                                            tooltip="Introduce tu email" tooltip-class="tooltip-help"
                                            class="campo-login" color="dark" type="email" formControlName="email" name="email" (keyup.enter)="password.setFocus()">

                                </div>
                            </div>
                        </div>
                        <div>
                            <div size-Xs="10" offset-Xs="1" size-sm="8" offset-sm="2" size-xl="8" offset-xl="2"   >
                                <br/>
                                <h5 class="lab-login">Password:</h5>
                                <div
                                        tooltip="Introduce tu contraseña" tooltip-class="tooltip-help"
                                        lines="none" class="ion-no-padding" color="transparent">
                                    <input class="campo-login" type="password" color="dark" formControlName="password" name="password" #password (keyup.enter)="onSubmit()">

                                </div>
                            </div>
                        </div>
                        <div>
                            <div size-Xs="10" offset-Xs="1" size-sm="8" offset-sm="2" size-xl="8" offset-xl="2" class="ion-text-left">
                                <a color="medium" (click)="forgot()">Did you forget your password? ?</a>
                            </div>
                        </div>
                        <div>
                            <div sizeXs="10" offsetXs="1" size="8" offset="2" class="ion-text-center">
                                <button [disabled]="logginIn" color="primary" type="submit" name="action" size="large" >Login</button>
                                <p><br/></p>
                            </div>
                        </div>
                    </div>
                    <div>
                    <h1>Active token:</h1>
                    <p>{{activeToken}}</p>
                      </div>



            </div>
        </div>
    </div>
    </form>
</div>
