<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <h1> Patient </h1>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>
<div class="container" fxLayout="row" fxLayoutGap="40px">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px" fxFlex="100">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="addElement()">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.firstname"  fxFlex="100">
        <mat-label>First name</mat-label>
        <input matInput
              formControlName="firstname"
              type="text"
              placeholder="Patient Name">
        <mat-error *ngIf="f.firstname.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="f.surname" fxFlex="100">
        <mat-label>Surname</mat-label>
        <input matInput
              formControlName="surname"
              type="text"
              placeholder="Surname">
        <mat-error *ngIf="f.surname.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.email" fxFlex="100">
        <mat-label>Email</mat-label>
        <input
        matInput
        formControlName="email"
        type="email"
        placeholder="Email">
        <mat-error *ngIf="f.email.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="f.email.hasError('email')">
          Please enter a <strong>valid email</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="f.mobile_number" fxFlex="100">
        <mat-label>Mobile number</mat-label>
        <input
          matInput
          formControlName="mobile_number"
          type="number"
          placeholder="mobile_number">
          <mat-error *ngIf="f.mobile_number.errors">
            Please enter a <strong>number</strong>
          </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.parent_name" fxFlex="100">
        <mat-label>Parent name</mat-label>
        <input
        matInput
        formControlName="parent_name"
        type="text"
        placeholder="Parent name">
      </mat-form-field>

      <mat-form-field *ngIf="f.parent_email" fxFlex="100">
        <mat-label>Parent email</mat-label>
        <input
          matInput
          formControlName="parent_email"
          type="email"
          placeholder="parent_email">
        <mat-error *ngIf="f.email.hasError('email')">
          Please enter a <strong>valid email</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.parent_mobile_number" fxFlex="100">
        <mat-label>Parent mobile number</mat-label>
        <input
        matInput
        formControlName="parent_mobile_number"
        type="number"
        placeholder="Parent mobile number">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.address1" fxFlex="100">
        <mat-label>Address 1</mat-label>
        <input
        matInput
        formControlName="address1"
        type="text"
        placeholder="address1">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.address2" fxFlex="100">
        <mat-label>Address 2</mat-label>
        <input
          matInput
          formControlName="address2"
          type="text"
          placeholder="address2">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.country" fxFlex="100">
        <mat-label>Country</mat-label>
        <input
          matInput
          formControlName="country"
          type="text"
          placeholder="country">
      </mat-form-field>

      <mat-form-field *ngIf="f.town" fxFlex="100">
        <mat-label>Town</mat-label>
        <input
          matInput
          formControlName="town"
          type="text"
          placeholder="town">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field *ngIf="f.staff_id" fxFlex="100">
        <mat-label>Staff id</mat-label>
        <input
          matInput
          formControlName="staff_id"
          type="text"
          placeholder="Staff id">
      </mat-form-field>

      <mat-form-field *ngIf="f.student_id" fxFlex="100">
        <mat-label>Student id</mat-label>
        <input
          matInput
          formControlName="student_id"
          type="text"
          placeholder="Student id">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field fxFlex="100">
        <mat-label>Laboratory</mat-label>
        <mat-select name="laboratory_id" formControlName="laboratory_id">
          <mat-option *ngFor="let r of laboratories" [value]="r.id">
            {{r.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.laboratory_id.hasError('required')">
          Field is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <mat-form-field fxFlex="100">
        <mat-label>Document type</mat-label>
        <mat-select name="document_type" formControlName="document_type" (selectionChange)="documentChanged($event)">
          <mat-option disabled="" selected="" value="">* Document type</mat-option>
          <mat-option *ngFor="let t of type_ids"  [value]="t.id">{{t.type_id}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="100">
        <mat-label>Document number</mat-label>
        <input
          matInput
          formControlName="document_number"
          type="text"
          placeholder="Document number">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <h2> Groups </h2>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
        <ul class="no-list">
          <li *ngFor="let p of groups">
          <mat-checkbox
            [checked]="isChecked(p)"
            (change)="check(p)">
            {{p.name}} <div class="group-icon" [style.background]="'#' + p.color"></div>
          </mat-checkbox>
        </li>
        </ul>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="40px">
      <button
      mat-raised-button
      color="warn"
      (click)="cancel()">
    Cancel
    </button>

    <button
      mat-raised-button
      color="primary">
      Save
    </button>
    </div>
  <br>
  </form>


</div>





