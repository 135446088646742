import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatCardModule } from '@angular/material/card';


@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.less']
})
export class PatientFormComponent implements OnInit {
  editMode = [false, false, false];
  clauses = [];
  futureClauses = [];
  settings: any;
  personalData: any;
  representative: any;
  accountRepresentativeData: any;
  available_inputs = [
    {name: 'email', text: 'Email'},
    {name: 'firstname', text: 'First name'},
    {name: 'surname', text: 'Surname'},
    {name: 'birthdate', text: 'Birth date'},
    {name: 'mobile_number', text: 'Mobile number'},
    {name: 'parent_name', text: 'Parent name'},
    {name: 'parent_email', text: 'Parent email'},
    {name: 'parent_mobile_number', text: 'Parent mobile number'},
    {name: 'laboratory', text: 'Laboratory'},
    {name: 'travel_information', text: 'Travel Information'},
    {name: 'vaccination_status', text: 'Vaccination status'},
    {name: 'document_number', text: 'Document number'},
    {name: 'document_type', text: 'Document type'},
    {name: 'country', text: 'Country'},
    {name: 'town', text: 'Town'},
    {name: 'postcode', text: 'Post code'},
    {name: 'address1', text: 'Address 1'},
    {name: 'address2', text: 'Address 2'},
    {name: 'staff_id', text: 'Staff id'},
    {name: 'student_id', text: 'Student id'},
    {name: 'ethnicity', text: 'Ethnicity'},
    {name: 'gender', text: 'Gender'},
  ];
  selectedDisabledItems = [];
  form: FormGroup = this.formBuilder.group({
    disabled_fields: [''],
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private auth: AuthService) { }

    async ngOnInit() {
      console.log("Get settings");
      //this.settings = await this.api.get('account');
      this.settings = await this.api.getAccount();
      console.log(typeof this.settings.clauses);
      console.log(this.settings.clauses);
      // const {representative, ...personalData} = this.settings
      // console.log("rep", representative)
      // console.log("pdata", personalData)
      this.clauses = this.settings.clauses;
      this.futureClauses = this.settings.futureClauses;
      this.personalData = {
        'Account Name': ['name', this.settings.name],//'Sed porttitor lectus nibh.',
        'Project ID': ['project_id', this.settings.project_id],//'A12342',
        'Tag (Region)': ['tag', this.settings.tag],//'North Englanc',
        'Country or region': ['country', this.settings.country],//'UK',
        'Town / City': ['city', this.settings.city],//'Gullford',
        'Post Code': ['postcode', this.settings.postcode],//'YW6 7HG',
        'Privacy terms': ['privacyTerms', this.settings.privacyTerms],
        'Links': ['links', this.settings.links],
      };

      this.accountRepresentativeData = {
        'Name': ['name', this.settings.representative.name],//'Aurore',
        'Surname': ['surname', this.settings.representative.surname],//'Aurore',
        'e-mail address': ['email', this.settings.representative.email],//'a.priore@surrey.co.uk',
        'Mobile number': ['mobile_number', this.settings.representative.mobile_number],//'0718291020'
      }


      for(let p of this.settings.disabled_fields){
        this.selectedDisabledItems.push(p);
      }


    }

    get f() { return this.form.controls; }


    edit(index: number){
      if(this.editMode[index] === true){
        this.settings.disabled_fields = this.selectedDisabledItems;
        this.clauses = this.clauses.filter(x => x.text !== '');
        this.futureClauses = this.futureClauses.filter(x => x.text !== '');
        this.settings.clauses = this.clauses;
        this.settings.futureClauses = this.futureClauses;
        this.api.updateAccount(this.settings).subscribe(resp => console.log(resp));
        console.log(this.settings);
      }
      this.editMode[index] = !this.editMode[index];

    }

    isChecked(name: string){
      return this.selectedDisabledItems.filter(x => x === name).length > 0;
    }

    checkClause(c: any){
      const index = this.clauses.findIndex(c);
      this.clauses[index].required = !this.clauses[index].required;
    }

    check(name: string){
      if(!this.isChecked(name)) this.selectedDisabledItems.push(name);
      else this.selectedDisabledItems = this.selectedDisabledItems.filter(x => x !== name);
    }
    addClause(){
      this.clauses.push({required: false, text: ''});
    }
    addFutureClause(){
      this.futureClauses.push({required: false, text: ''});
    }

}
