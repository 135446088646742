
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
  <h1>Changelog</h1>
  <mat-icon color="warn" (click)="cancel()" aria-hidden="false" aria-label="Close">close</mat-icon>
</div>

<table>
  <tr><th>Version</th><th>Description</th></tr>
  <tr *ngFor="let c of changes">
    <td>{{ c.version }}</td>
    <td>{{ c.description }}</td>
  </tr>
</table>
