<app-account-menu></app-account-menu>
<div class="">
  <nav mat-tab-nav-bar>
    <a mat-tab-link routerLink="/users"> Users </a>
    <a mat-tab-link routerLink="/roles"> Roles </a>
    <a mat-tab-link [active]="true" routerLink="/groups"> Groups </a>
  </nav>
</div>
<div class="result-box">
  <div class="">
    <div class="container" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="40px">
      <mat-form-field class="full-width" *ngIf="dataSource"  fxFlex="70" fxLayout="column">
        <span matPrefix><mat-icon color="primary">search</mat-icon></span>
        <mat-label>Search by</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. name" #input>
      </mat-form-field>
      <button *ngIf="dataSource"  fxFlex="30" fxLayout="column" mat-raised-button color="primary" (click)="openEdit()">Add group</button>
    </div>
    <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort >

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="tag">
        <th mat-header-cell *matHeaderCellDef> Tag </th>
        <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef> Color </th>
        <td mat-cell *matCellDef="let element"> <div class="group-icon" [style.background]="'#' + element.color"></div> </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" (click)="openEdit(element)">
            <mat-icon class="mat-18">mode</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="warn" (click)="delete(element)">
            <mat-icon class="mat-18">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" (click)="select(myRowData)"></tr>

    </table>
    <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>

  </div>
</div>
